import { ArrowBack, Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  keyframes,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/search-icon.svg";
import { SearchContext } from "../Context/searchContext";

const slideIn = keyframes`
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(0);
  }
`;

const Booking2 = ({
  onSelectLocation,
}: {
  onSelectLocation: (location: string) => void;
}) => {
  const navigate = useNavigate();
  const { searchValue, handleSearchChange, setSearchValue } =
    useContext(SearchContext);

  useEffect(() => {
    return () => {
      setSearchValue("");
    };
  }, [setSearchValue]);

  return (
    <Box
      sx={{
        animation: `${slideIn} 0.5s ease-out`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: 2,
        }}
        m={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography fontWeight={800} fontSize={20}>
          From where
        </Typography>
      </Box>
      <Box px={2}>
        <TextField
          placeholder="Search state or city"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />{" "}
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          value={searchValue}
          fullWidth
          inputProps={{
            sx: {
              "&::placeholder": {
                p: 1,
                mt: 1.5,
              },
            },
          }}
          sx={{
            bgcolor: "#F9F9F9",
            mt: 2,
            border: "none",
            p: 1,
            px: 2,
            borderRadius: "8px",
          }}
        />
        <Typography fontWeight={600} fontSize="14px" color="#101828" my={2}>
          History
        </Typography>
        <Box>
          {location
            .filter((item) => {
              const lowerCaseSearchValue = searchValue.toLowerCase();
              const upperCaseSearchValue = searchValue.toUpperCase();
              return lowerCaseSearchValue === ""
                ? item
                : item.place.toLowerCase().includes(lowerCaseSearchValue) ||
                    item.place.toUpperCase().includes(upperCaseSearchValue);
            })
            .map((location) => (
              <Box
                key={location.place}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                border="1px solid #E4E7EC"
                p="10px 14px"
                borderRadius="8px"
                mb={1}
                onClick={() => {
                  onSelectLocation(location.place);
                }}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontWeight={600} fontSize="12px">
                  {location.place}
                </Typography>
                <Close />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Booking2;

const location = [
  { place: "LAGOS" },
  { place: "ABUJA" },
  { place: "KANO" },
  { place: "IBADAN" },
  { place: "PORT HARCOURT" },
  { place: "BENIN CITY" },
  { place: "MAIDUGURI" },
  { place: "JOS" },
  { place: "ENUGU" },
  { place: "KADUNA" },
  { place: "ILORIN" },
  { place: "OWERRI" },
  { place: "CALABAR" },
  { place: "SOKOTO" },
  { place: "UYO" },
  { place: "AWKA" },
  { place: "MINNA" },
  { place: "YOLA" },
  { place: "WARRI" },
  { place: "ONITSHA" },
];
