import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import ParkManagerDetails from './ParkManagerDetails';
import Move9jaCard from './Move9jaCard';
import CustomTimeline from './TravelLocationTimeline';
import CheckIcon from '../assets/check-circle.svg';
import BottomDrawer from './BottomDrawer';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import TravelDetail from './TravelDetail';

const PaymentSuccess = ({
  bookingDate,
  bookingId,
  busName,
  timeOfTravel,
  seatNumber,
  parkName,
  price,
  from,
  to,
  parkBusStop,
  stopLocation,
  estimatedHours,
}: {
  bookingDate: string | null;
  bookingId: string | null;
  busName: string | null;
  timeOfTravel: string | null;
  seatNumber: string | null;
  parkName: string | null;
  price: number | null;
  from: string | null;
  to: string | null;
  parkBusStop: string | null;
  stopLocation: string | null;
  estimatedHours: string | null;
}) => {
  const navigate = useNavigate();
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <>
      <BottomDrawer open={openDetails} onClose={() => setOpenDetails(false)}>
        <TravelDetail
          bookingDate={bookingDate}
          bookingId={bookingId}
          busName={busName}
          timeOfTravel={timeOfTravel}
          seatNumber={seatNumber}
          parkName={parkName}
          price={price}
          from={from}
          to={to}
          parkBusStop={parkBusStop}
          stopLocation={stopLocation}
          estimatedHours={estimatedHours}
        />
      </BottomDrawer>
      <Box>
        <Box p={2}>
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <Close
              fontSize='large'
              fontWeight={100}
              onClick={() => navigate('/home')}
              sx={{ cursor: 'pointer' }}
            />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            mt={3}
            mb={2}
          >
            <img
              src={CheckIcon}
              alt='check-icon'
              style={{
                height: 53.33,
                width: 53.33,
              }}
            />

            <Typography mt={2} fontSize='20px' fontWeight={700}>
              Successful
            </Typography>
            <Typography
              mx={4}
              textAlign='center'
              mt={2}
              fontSize='12px'
              fontWeight={400}
            >
              You've successfully book your bus ticket with order no:{' '}
              <span color='black' style={{ fontWeight: 600 }}>
                {bookingId}
              </span>{' '}
              {from} to {to}. A copy of your ticket details has been sent to
              your mail.
            </Typography>
          </Box>
          <Move9jaCard
            title={`${bookingDate}`}
            endText={`${estimatedHours || 6}h`}
          >
            <CustomTimeline
              to={to}
              from={from}
              startTime={timeOfTravel}
              seat={seatNumber}
              busName={busName}
              parkBusStop={parkBusStop}
              parkName={parkName}
              estimatedHours={estimatedHours}
              stopLocation={stopLocation}
            />
          </Move9jaCard>
          <ParkManagerDetails />
          <Button
            fullWidth
            sx={{
              borderRadius: '8px',
              bgcolor: '#F2F4F7',
              color: '#000',
              p: '10px 14px',
              mt: 2,
            }}
            variant='contained'
          >
            Download Ticket
          </Button>
          <Button
            fullWidth
            sx={{ borderRadius: '8px', p: '10px 14px', mt: 2 }}
            variant='contained'
            onClick={() => setOpenDetails(true)}
          >
            See full details
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PaymentSuccess;
