import { ContentCopy } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";

import spookySticker from "./../../assets/spookyImage.png";

const ReferralLinkContent = () => {
  return (
    <>
      <Box
        height={200}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ backgroundColor: "#E8FFF2" }}
      >
        <img
          src={spookySticker}
          style={{ objectFit: "cover" }}
          alt=""
          width={150}
          height={150}
        />
      </Box>
      <Box p={2}>
        <Typography
          py={1}
          variant="body2"
          fontSize={22}
          textAlign="left"
          fontWeight={700}
        >
          Earn NGN 500 when your referral books for a trip
        </Typography>
        <Box mb={3}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1,
                my: 1,
              }}
            >
              <Typography fontSize={12}>Link</Typography>
              <Typography fontSize={12} fontWeight={600}>
                www.move9ja.com/ref=904moski{" "}
                <ContentCopy sx={{ height: 12, width: 12, color: "green" }} />
              </Typography>
            </Box>
            <Divider />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1,
                my: 1,
              }}
            >
              <Typography fontSize={12}>Code</Typography>
              <Typography fontSize={12} fontWeight={600}>
                MOSKI{" "}
                <ContentCopy sx={{ height: 12, width: 12, color: "green" }} />
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
        <Box mb={2} mt={12}>
          <Button
            fullWidth
            disableElevation
            sx={{
              bgcolor: " #F2F4F7",
              color: "#000",
              p: 1,
              borderRadius: 2.5,
              fontWeight: 500,
            }}
            variant="contained"
          >
            Share your link
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            disableElevation
            fullWidth
            sx={{
              p: 1,
              borderRadius: 2.5,
              bgcolor: "#000",
              color: "#fff",
              fontWeight: 400,
            }}
            variant="contained"
          >
            See referral performance
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ReferralLinkContent;
