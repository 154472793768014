import { Box, Button, Divider, Typography } from "@mui/material";

import Emergency from "./../assets/not-assigned.svg";
import smallCheck from "./../assets/smallTick.svg";
import { CustomSlider } from "./TripOrderContent";

const EmergencyServices = () => {
  return (
    <>
      <Box
        height={225}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ backgroundColor: "#E8FFF2" }}
      >
        <img
          src={Emergency}
          alt=""
          width="112.5px"
          height="112.5px"
          style={{ objectFit: "cover" }}
        />
      </Box>
      <Box p={3}>
        <Typography
          variant="body2"
          fontSize="24px"
          textAlign="left"
          fontWeight={700}
          mb={2}
        >
          Emergency services have been alerted.
        </Typography>
        <Box>
          {services.map(({ title }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                mb: "8px",
              }}
            >
              <img
                src={smallCheck}
                alt=""
                style={{ height: "24px", width: "24px" }}
              />
              <Typography fontSize={12}>{title}</Typography>
            </Box>
          ))}
        </Box>
        <Box mb={2} mt={4}>
          <CustomSlider text="I am okay now" />
        </Box>
      </Box>
    </>
  );
};

export default EmergencyServices;

const services = [
  {
    title: "We have alerted emergency authorities. Help is on the way.",
  },
  {
    title:
      "Please move to a safe location if possible. Stay on the line with emergency services.",
  },
  {
    title:
      "Our support team is also aware of the situation and will assist as needed.",
  },
  {
    title: "Estimated response time is 5mins",
  },
  {
    title: "We have shared your location with your next of KIN contact.",
  },
];
