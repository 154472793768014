import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BottomDrawer from "../../shared/BottomDrawer";
import PopUp from "./PopUp";
import { useState } from "react";

const F10 = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Box height="100vh" mx={1} my={2} display="flex" flexDirection="column">
        <Box flexGrow={5} display="flex" flexDirection="column">
          <Box sx={{ display: "flex", alignItems: "start" }}>
            <IconButton
              onClick={() => {
                navigate("/faq");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Is my personal information secure?
            </Typography>
          </Box>
          <Typography fontSize={14} mx={2} mt={6}>
            Absolutely! Our app uses encryption and secure servers to protect
            your data.
          </Typography>
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="column" mt="auto">
          <Divider />
          <Typography my={2} textAlign="center" fontWeight={600} fontSize={16}>
            Does this information solve your issue?
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="8px"
          >
            <Button
              sx={{
                fontWeight: 400,
                bgcolor: "transparent",
                borderRadius: "100px",
                p: "6px 35px",
                color: "#000000",
                border: "1px solid #CFCFCF",
              }}
              variant="outlined"
              onClick={() => setOpen(true)}
            >
              YES
            </Button>
            <Button
              sx={{
                fontWeight: 400,
                bgcolor: "transparent",
                borderRadius: "100px",
                p: "6px 35px",
                color: "#000000",
                border: "1px solid #CFCFCF",
              }}
              variant="outlined"
            >
              No
            </Button>
          </Box>
        </Box>
      </Box>
      <BottomDrawer
        drawerHeight="45vh"
        open={open}
        onClose={() => setOpen(false)}
      >
        <PopUp />
      </BottomDrawer>
    </>
  );
};

export default F10;
