import {
  ArrowBack,
  CardGiftcard,
  LocalFireDepartment,
  NavigateNext,
} from "@mui/icons-material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import useThemeColor from "../../hooks/useThemeColor";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Card,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";
import { keyframes } from "@emotion/react";
import { Check } from "@mui/icons-material";
import BottomDrawer from "../../shared/BottomDrawer";
import ReferralLinkContent from "../../shared/BottomDrawerContent/ReferalLinkContent";
import { useSearchParams } from "react-router-dom";
import useGetUser from "../../hooks/useGetUser";
import ProfileDetails from "./ProfileDetails";
import { useMutation } from "@tanstack/react-query";
import { updatePhoto } from "../../services/user_settings";
import toast from "react-hot-toast";

export const Profile = () => {
  const PROFILELIST = [
    {
      title: "Update profile",
      onClick: () => {
        navigate("/profile-details");
      },
    },
    {
      title: "Medical Info",
      onClick: () => {
        navigate("/medical-info");
      },
    },
    {
      title: "App Appearance",
      onClick: () => {
        navigate("/app-appearance");
      },
    },
    {
      title: "Privacy Policy",
      onClick: () => {
        navigate("/privacy-policy");
      },
    },
    {
      title: "Terms of Use",
      onClick: () => {
        navigate("/terms&conditions");
      },
    },
    {
      title: "Rate the app",
      onClick: () => {
        navigate("/payouts");
      },
    },

    {
      title: "Contact Us",
      onClick: () => {
        navigate("/contact-us");
      },
    },
  ];
  const navigate = useNavigate();

  const data = useGetUser();

  console.log("Get user data", data);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return await updatePhoto(data);
    },
    onSuccess: (data) => {
      toast.success("Uploaded Successfully!");
      console.log(data, "pic");
    },
    onError: (err) => {
      console.log(err);
      toast.error(`${err}`);
    },
  });
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      mutation.mutate(file);
    }
  };

  const handleAvatarClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useThemeColor("#000");
  const [open, setOpen] = React.useState(false);
  return (
    <DashboardLayout>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        defaultDesign
      >
        <ReferralLinkContent />
      </BottomDrawer>

      <Box
        display={{ xs: "block", md: "grid" }}
        gridTemplateColumns="1fr 1fr"
        mx={{
          md: "14%",
        }}
        gap={6}
      >
        <Box>
          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
            }}
            mb={1}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Profile
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: "#000000",
              height: "284px",
            }}
            mb={1}
          >
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "start",
                gap: 2,
              }}
              display={{ xs: "flex", md: "none" }}
              p={2}
            >
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack sx={{ color: "#FFFFFF" }} />
              </IconButton>
              <Typography fontWeight={700} fontSize={20} color="white">
                Profile
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mx={3}
              pt={{ md: 4 }}
            >
              <Stack
                bgcolor="#1570EF"
                color="#FFF"
                height={59}
                width={59}
                justifyContent="center"
                alignItems="center"
                borderRadius="100%"
                position="relative"
                onClick={handleAvatarClick}
              >
                <Avatar
                  src={selectedFile ? URL.createObjectURL(selectedFile) : ""}
                />
                <Stack
                  position="absolute"
                  bottom={0}
                  right={0}
                  borderRadius="100%"
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={22}
                  width={22}
                >
                  <IconButton
                    onClick={() => {
                      navigate("/profile-details");
                    }}
                  >
                    <DriveFileRenameOutlineIcon
                      sx={{ color: "green", height: 12, width: 12 }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Typography color="#FFF" fontSize={18} my={1}>
                {/* {`${user?.firstName} ${user?.lastName}`} */}
                {data?.firstName + " " + data?.lastName}
              </Typography>
              <Typography
                color="#FFF"
                fontSize={10}
                display="flex"
                gap={2}
                alignItems="center"
              >
                <p>{data?.email}</p>
                <Chip
                  size="small"
                  label="Verified"
                  sx={{ bgcolor: "#fff", color: "#667085", fontSize: "12px" }}
                  icon={<LocalFireDepartment sx={{ color: "green" }} />}
                />
              </Typography>
            </Box>
            <Divider variant="fullWidth" color="gray" sx={{ my: 2 }} />
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Box
              px={3}
              borderColor="#FFF"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box textAlign="center">
                <Typography fontSize={10} color="#667085">
                  Total Travelled
                </Typography>
                <Typography fontSize={12} color="#FFF">
                  45
                </Typography>
              </Box>
              <Box textAlign="center">
                <Typography fontSize={10} color="#667085">
                  Distance travelled
                </Typography>
                <Typography fontSize={12} color="#FFF">
                  7,894km
                </Typography>
              </Box>
              <Box textAlign="center">
                <Typography fontSize={10} color="#667085">
                  Total Referred
                </Typography>
                <Typography fontSize={12} color="#FFF">
                  7
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box p={2}>
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1.5,
                mb: 1,
                borderRadius: 3,
                bgcolor: "#9747FF",
              }}
              fullWidth
              variant="contained"
              onClick={() => setOpen(true)}
            >
              <Typography
                fontSize={13}
                fontWeight={500}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CardGiftcard />
                NGN 500 gift for you
              </Typography>
              <NavigateNext fontSize="medium" sx={{ height: 20, width: 20 }} />
            </Button>
            {PROFILELIST.map((item) => (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: 2,
                    cursor: "pointer",
                  }}
                  onClick={item.onClick}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <NavigateNext
                    fontSize="medium"
                    sx={{ height: 20, width: 20 }}
                  />
                </Box>
                <Divider />
              </Box>
            ))}
            <Button
              onClick={async () => {
                console.log("LOG_OUT");
                await localStorage.clear();
                //  window.location.reload();
                window.location.href = "/login";

                console.log("Log Item TOKEN: ", localStorage.getItem("token"));
              }}
              variant="contained"
              fullWidth
              sx={{
                mt: "16px",
                bgcolor: "#FFF5F4",
                color: "#DA4733",
                fontWeight: 400,
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
        <Box
          display={{ xs: "none", md: "flex" }}
          alignItems="flex-start"
          justifyContent="start"
          flexDirection="column"
          mt={5}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              fontWeight={700}
              fontSize={20}
              sx={{ whiteSpace: "nowrap" }}
            >
              Update Profile
            </Typography>
            <Typography ml="100%">Edit</Typography>
          </div>
          <ProfileDetails />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

const comeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;
// const ProfileDetail = () => {
//   const [firstName, setFirstName] = React.useState('');
//   const [lastName, setLastName] = React.useState('');
//   const [userName, setUserName] = React.useState('');
//   const [dob, setDob] = React.useState('');
//   const [phone, setPhone] = React.useState('');
//   const [email, setEmail] = React.useState('');
//   const [saved, setSaved] = React.useState(false);
//   const [open, setOpen] = React.useState(false);
//   const [openDialog, setOpenDialog] = React.useState(false);

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };
//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//   };
//   const [searchParams] = useSearchParams();

//   const openParam = searchParams.get('open');

//   useLayoutEffect(() => {
//     if (openParam) {
//       setOpen(true);
//     }
//   }, []);

//   const navigate = useNavigate();
//   return (
//     <>
//       <Box p={2} position='relative'>
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'start',
//             gap: 2,
//           }}
//           mb={1}
//         >
//           <IconButton
//             onClick={() => {
//               navigate(-1);
//             }}
//           >
//             <ArrowBack />
//           </IconButton>
//           <Typography fontWeight={700} fontSize={20}>
//             Profile details
//           </Typography>
//         </Box>
//         <form autoComplete='off' onSubmit={handleSubmit}>
//           <Typography mb={4} fontWeight={500}>
//             Complete profile details correctly
//           </Typography>
//           <Box
//             display='flex'
//             alignItems='center'
//             justifyContent='space-between'
//             gap={2}
//           >
//             <FormControl variant='standard' fullWidth>
//               <InputLabel shrink sx={{ fontWeight: 800 }}>
//                 First Name
//               </InputLabel>
//               <TextField
//                 placeholder='John'
//                 variant='standard'
//                 InputProps={{ disableUnderline: true }}
//                 sx={{
//                   bgcolor: '#F9F9F9',
//                   mt: 2.5,
//                   py: 1,
//                   px: 2,
//                   borderRadius: '10px',
//                 }}
//                 type='text'
//                 value={firstName}
//                 onChange={(e) => setFirstName(e.target.value)}
//               />
//             </FormControl>
//             <FormControl variant='standard' fullWidth>
//               <InputLabel shrink sx={{ fontWeight: 800 }}>
//                 Last Name
//               </InputLabel>
//               <TextField
//                 placeholder='Doe'
//                 type='text'
//                 variant='standard'
//                 InputProps={{ disableUnderline: true }}
//                 sx={{
//                   bgcolor: '#F9F9F9',
//                   borderRadius: '10px',
//                   borderStyle: 'none',
//                   mt: 2.5,
//                   py: 1,
//                   px: 2,
//                 }}
//                 value={lastName}
//                 onChange={(e) => setLastName(e.target.value)}
//               />
//             </FormControl>
//           </Box>
//           <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
//             <InputLabel shrink sx={{ fontWeight: 800 }}>
//               Username
//             </InputLabel>
//             <TextField
//               placeholder='jonny'
//               variant='standard'
//               InputProps={{ disableUnderline: true }}
//               sx={{
//                 bgcolor: '#F9F9F9',
//                 mt: 2.5,
//                 mb: 2,
//                 py: 1,
//                 px: 2,
//                 borderRadius: '10px',
//               }}
//               type='text'
//               value={userName}
//               onChange={(e) => setUserName(e.target.value)}
//             />
//           </FormControl>
//           <FormControl variant='standard' fullWidth>
//             <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
//               Date of Birthday <span>(optional)</span>
//             </InputLabel>
//             <TextField
//               placeholder='DD-MM-YYYY'
//               type='date'
//               variant='standard'
//               InputProps={{ disableUnderline: true }}
//               sx={{
//                 bgcolor: '#F9F9F9',
//                 mt: 2,
//                 borderRadius: '10px',
//                 borderStyle: 'none',
//                 py: 1,
//                 px: 2,
//               }}
//               value={dob}
//               onChange={(e) => setDob(e.target.value)}
//             />
//           </FormControl>
//           <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
//             <InputLabel shrink sx={{ fontWeight: 800 }}>
//               Phone Number
//             </InputLabel>
//             <TextField
//               placeholder='+234'
//               variant='standard'
//               InputProps={{ disableUnderline: true }}
//               sx={{
//                 bgcolor: '#F9F9F9',
//                 mt: 2.5,
//                 mb: 2,
//                 py: 1,
//                 px: 2,
//                 borderRadius: '10px',
//               }}
//               type='number'
//               value={phone}
//               onChange={(e) => setPhone(e.target.value)}
//             />
//           </FormControl>
//           <FormControl variant='standard' fullWidth>
//             <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
//               Email Address
//             </InputLabel>
//             <TextField
//               placeholder='Enter a valid email'
//               type='email'
//               variant='standard'
//               InputProps={{ disableUnderline: true }}
//               sx={{
//                 bgcolor: '#F9F9F9',
//                 mt: 2,
//                 borderRadius: '10px',
//                 borderStyle: 'none',
//                 py: 1,
//                 px: 2,
//               }}
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </FormControl>

//           <Button
//             variant='contained'
//             color='primary'
//             fullWidth
//             sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
//             type='submit'
//             onClick={() => setSaved(true)}
//           >
//             Continue
//           </Button>
//           <Button
//             variant='contained'
//             fullWidth
//             component='button'
//             sx={{
//               fontWeight: 400,
//               p: 1,
//               borderRadius: 2,
//               bgcolor: '#FFF5F4',
//               color: 'red',
//               // width: "content-fit",
//             }}
//             type='submit'
//             onClick={handleOpenDialog}
//           >
//             Delete account
//           </Button>

//           <Backdrop open={openDialog} onClick={handleCloseDialog}>
//             <Box height='25%' width='75%' bgcolor='white' borderRadius='12px'>
//               <Typography p={2} height='70%'>
//                 Are you sure?
//               </Typography>
//               <Box
//                 display='grid'
//                 gridTemplateColumns='1fr 1fr'
//                 sx={{ placeItems: 'center' }}
//                 borderTop='1px solid black'
//               >
//                 <Typography>Cancel</Typography>
//                 <Typography>Yes</Typography>
//               </Box>
//             </Box>
//           </Backdrop>
//         </form>
//         {/* This box is to display the saved successfully  when ever the user completes his or her profile */}
//       </Box>

//       {saved && (
//         <Card
//           variant='elevation'
//           elevation={2}
//           sx={{
//             backgroundColor: '#E8FFF2',
//             color: 'white',
//             width: '100%',
//             animation: `${comeIn} 2s ease-in-out`,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             gap: 1,
//             position: 'absolute',
//             top: 0,
//             borderRadius: 0,
//             p: 1,
//           }}
//         >
//           <Typography fontSize={14} color='green'>
//             Saved successfully
//           </Typography>
//           <Check sx={{ color: 'green' }} />
//         </Card>
//       )}
//     </>
//   );
// };
