import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  userId: string;
  username: string;
  role: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  dob?: Date;
}

export const getUserFromToken = (): DecodedToken | null => {
  const token = localStorage.getItem("token");

  if (!token) return null;

  try {
    const decoded: DecodedToken = jwtDecode(token);
    console.log(decoded);
    return decoded;
  } catch (error) {
    console.error("Failed to decode token:", error);
    return null;
  }
};
