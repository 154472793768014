import { ArrowBack, HelpOutlineRounded } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import useThemeColor from "./../hooks/useThemeColor";
import DashboardLayout from "./../layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";

export const EarnigsBreakdown = () => {
  const earnings = [
    {
      title: "Total booking cost",
      value: "+ NGN354,890.98",
    },
    { title: "Add ons", value: "+ NGN70,000.00" },
    { title: "Your Earnings", value: "- NGN64,578.89" },
    { title: "Total Remitted", value: "+ NGN478,890.00" },
  ];
  const commissions = [
    { title: "Your Commission(10%)", value: "+ NGN700.00" },
    {
      title: "Service Fee",
      subtitle: "per booking(14)",
      value: "+ NGN70,000.00",
    },
    {
      title: "Incentive",
      subtitle: "per booking(14)",
      value: "+ NGN2,000.00",
    },
    { title: "VTA(1.5)", value: "- NGN330.89" },
    { title: "Total Earnings", value: "NGN64,578.89" },
  ];
  const navigate = useNavigate();
  useThemeColor("#000");
  return (
    <DashboardLayout>
      <Box px={1} py={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Earnings Breakdown
            </Typography>
          </Box>

          <HelpOutlineRounded />
        </Box>
        <Box p={3}>
          {earnings.map((item) => (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                }}
              >
                <Typography fontSize={12} fontWeight={500}>
                  {item.title}
                </Typography>
                <Typography fontSize={12} fontWeight={500}>
                  {item.value}
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
        <Box px={3} py={2}>
          {commissions.map((item) => (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                }}
              >
                <Box>
                  <Typography fontSize={12} fontWeight={500}>
                    {item.title}
                  </Typography>
                  <Typography color="#98A2B3" fontSize={12} fontWeight={500}>
                    {item.subtitle}
                  </Typography>
                </Box>
                <Typography fontSize={12} fontWeight={500}>
                  {item.value}
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
        <Box px={2}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              my: 0.5,
              p: 1,
              bgcolor: "#F2F4F7",
              color: "#000",
              borderRadius: "8px",
              fontWeight: 400,
            }}
          >
            Download as pdf
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              my: 0.5,
              p: 1,
              color: "#fff",
              bgcolor: "#000",
              borderRadius: "8px",
              fontWeight: 400,
            }}
          >
            Make a withdrawal
          </Button>
        </Box>
        <Typography fontSize={12} px={2} mt={1}>
          Need more help? Read on our{" "}
          <span style={{ textDecoration: "underline" }}>Booking Policies</span>{" "}
          and{" "}
          <span style={{ textDecoration: "underline" }}>
            Terms of Agreement
          </span>
        </Typography>
      </Box>
    </DashboardLayout>
  );
};
