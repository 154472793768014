import { Box } from '@mui/material';
import { ReactNode } from 'react';
import SimpleBottomNavigation from '../shared/BottomNavigation';
import NavBar from './NavBar';

const DashboardLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Box
        display='grid'
        flexDirection={{ xs: 'column', md: 'row' }}
        minHeight='100vh'
        overflow='hidden'
      >
        <Box
          display={{ xs: 'none', md: 'block' }}
          width={{ xs: '0%', md: '100%' }}
          p={2}
        >
          <NavBar />
        </Box>
        <Box flex={1} minHeight='100vh' sx={{ overflowY: 'auto', pb: 13 }}>
          {children}
        </Box>
        {/* <Box
          display={{ xs: "none", md: "block" }}
          width={{ xs: "0%", md: "25%" }}
          p={2}
        >
          <Typography>Support</Typography>
        </Box> */}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100vw',
          zIndex: 1,
          display: { md: 'none', xs: 'block' },
        }}
      >
        <SimpleBottomNavigation />
      </Box>
    </>
  );
};

export default DashboardLayout;
