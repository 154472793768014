import http from "./http";

export const createBeneficiary = async (body: any) => {
  const res = http.post("/customer/create/createbeneficiary", body);
  return (await res).data;
};

export const uploadMedicationInfo = async (body: any) => {
  const res = http.post("/customer/profile/medical-information", body);
  return (await res).data;
};

export const uploadEmergencyContact = async (body: any) => {
  const res = http.post("/customer/profile/emergency-contact", body);
  return (await res).data;
};

export const getMedicationInfo = async () => {
  const res = http.get("/customer/profile/get-medical-information");
  return (await res).data;
};

export const getEmergencyContact = async () => {
  const res = http.get("/customer/profile/get-emergency-contact");
  return (await res).data;
};

export const sendDeleteAccoutOtp = async () => {
  const res = http.get("/customer/profile/delete-account-otp");
  return (await res).data;
};

export const deleteAccount = async (body: any) => {
  const res = http.post("/customer/profile/delete-account", body);
  return (await res).data;
};

export const updatePhoto = async (body: any) => {
  const res = http.post("/customer/profile/update-profile-picture", body);
  return (await res).data;
};
