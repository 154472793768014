import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";

const Refund = () => {
  return (
    <>
      <Box mx="5%">
        <Box pt={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
            }}
          >
            <IconButton>
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Back
            </Typography>
          </Box>
        </Box>
        <Typography mt={1} mb={3} fontSize={16}>
          Refunds and Rescheduling Policy
        </Typography>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Refund Policy
          </Typography>
          <ul style={{ paddingLeft: "16px" }}>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Instant Refund: Refunds are processed instantly, or within 1
                hour in case of any delay.
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Cancellation Fee: A 10% refund fee applies to customers who
                request a refund 2-3 hours before the trip booked is scheduled
                to begin.
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                No-Show Fee: A 20% refund fee applies to customers who fail to
                meet up at the designated park and time.
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Rescheduling Fee: An additional 5% fee applies to customers who
                want to reschedule their trip due to not meeting up at the
                designated time.
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Refund Amount
          </Typography>
          <ul style={{ paddingLeft: "16px" }}>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Before Trip Start: If cancellation occurs before the trip start
                date, the refund amount will be the total booking cost minus the
                10% cancellation fee.
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                After Trip Start or No-Show: If cancellation occurs after the
                trip has started or the customer fails to meet at the designated
                park, the refund amount will be the total booking cost minus the
                20% no-show fee.
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Rescheduling
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Customers can reschedule their trip by paying an additional 5% of
            the total booking cost.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Rescheduling is subject to availability and must be done at least
            2 hours before the trip start date.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Contact Us
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            For any questions or concerns regarding our refund and rescheduling
            policy, please don't hesitate to reach out to us at [insert contact
            information].
          </Typography>

          <Typography fontWeight={400} fontSize={12} my={1}>
            Please review and adjust this policy according to your specific
            needs and legal requirements.
          </Typography>
        </Box>

        <Box height="200px" />
        <Button variant="contained" fullWidth sx={{ fontWeight: 400 }}>
          Contact Us
        </Button>
        <Typography fontSize="12px" textAlign="center" mt="12px" mb="24px">
          By using the Move9ja Booking App, you acknowledge that you have read,
          understood, and agreed to our refund and rescheduling policy.
        </Typography>
      </Box>
    </>
  );
};

export default Refund;
