import { ContentCopy } from "@mui/icons-material";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import smallTick from "./../assets/smallTick.svg";
import React from "react";
import BottomDrawer from "./BottomDrawer";

const Delete = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Box
        p={2}
        display="flex"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        height="100%"
      >
        <Typography fontSize="24px" textAlign="center" fontWeight={400} mt={3}>
          Delete your Move9ja account?
        </Typography>

        <Button
          sx={{
            bgcolor: "#F04438",
            color: "#FFF",
            p: "12px 35px",
            borderRadius: "20px",
            fontWeight: 600,
            fontSize: "12px",
            width: "112px",
            mt: 6,
          }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Delete
        </Button>
      </Box>
    </>
  );
};

export default Delete;
