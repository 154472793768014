import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import move9jaLogo from '../../assets/move9ja.svg';
import backGroundImage from '../../assets/move9jaBoy.png';

import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation } from '@tanstack/react-query';
import { verifyOtp } from '../../services/auth';
import { handleMutationError } from '../../services/handler';
import AlertMessage from '../../components/AlertMessage';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface FormValue {
  otp: string;
  type: string;
}

const schema = yup.object({
  otp: yup.string().required('OTP code is required'),
  type: yup.string().oneOf(['email']).required(),
});

const EmailOtp = () => {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: 'email',
    },
  });

  useEffect(() => {
    console.log('Location obj>', location?.state.user_id);
  }, []);

  const mutation = useMutation({
    mutationFn: async (data: FormValue) => {
      return await verifyOtp(data);
    },
    onSuccess: (data) => {
      console.log('Data like actual data', data?.data);
      setSent(true);

      const userId = data?.data?.user_id;

      if (userId) {
        navigate('/complete-registration', {
          state: { userId: userId },
        });
      } else if (data?.data?.user_details?._id) {
        localStorage.setItem('token', data?.data?.token);
        localStorage.setItem(
          'user_details',
          JSON.stringify(data?.data?.user_details)
        );
        navigate('/');
      }
    },
    onError: (err) => {
      handleMutationError(err);
      setError(true);
      console.log(err);
    },
  });

  const onSubmit = (data: any) => {
    const b = {
      otp: data.otp,
      type: data.type,
      user_id: (location as any).state?.userId,
    };
    mutation.mutate(b as any);
  };

  return (
    <Box height='100vh'>
      <Box
        height='72px'
        p='0px 80px'
        display={{ xs: 'none', md: 'flex' }}
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
        <Button
          sx={{
            bgcolor: '#DA4733',
            width: '109px',
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '14px',
          }}
          variant='contained'
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'grid' },
          gridTemplateColumns: '40% 60%',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            height: '952px',
            width: '100%',
            margin: 0,
            padding: 0,
            display: { xs: 'none', md: 'grid' },
            placeItems: 'end',
          }}
        >
          <Typography
            color='#fff'
            fontSize='32px'
            fontWeight={600}
            height='92px'
            width='324px'
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display='flex'
          height={{ md: '100vh' }}
          alignItems='center'
          flexDirection='column'
        >
          <Box
            width={{ xs: '100%', md: '510px' }}
            p={{ md: '0px 24px', xs: '0px 10px' }}
            mt={{ md: '70px', xs: '20px' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: 1,
              }}
              mb={1}
            >
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={800} fontSize={20}>
                Verify your email address
              </Typography>
            </Box>
            <form
              style={{ marginLeft: 2, marginRight: 2 }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Typography mb={4} fontWeight={500}>
                Enter the verification code sent to your email
                {' ' + location?.state?.email}
              </Typography>

              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  OTP code
                </InputLabel>
                <Controller
                  name='otp'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Paste OTP'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: 3,
                        outline: 'none',
                        border: 'none',
                      }}
                      error={!!errors.otp}
                      helperText={errors.otp ? errors.otp.message : ''}
                    />
                  )}
                />
              </FormControl>
              <Button
                disabled={!!mutation.isPending}
                variant='contained'
                color='primary'
                fullWidth
                sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
                type='submit'
              >
                {mutation.isPending ? 'Verifying...' : 'Verify OTP'}
              </Button>
            </form>
            <Typography
              fontSize={12}
              fontWeight={500}
              textAlign='center'
              mt={1}
            >
              {/* Resend OTP {otpTime} */}
              {/* 0:59 */}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* {error && (
        <AlertMessage
          title="Incorrect token, Please enter the correct token or request a new one."
          icon={<ErrorOutline />}
          bgColor="#DA4733"
          textColor="white"
        />
      )}
      {sent && (
        <AlertMessage
          title="Correct OTP"
          icon={<Check />}
          bgColor="#E8FFF2"
          textColor="#379E66"
        />
      )} */}
    </Box>
  );
};

export default EmailOtp;
