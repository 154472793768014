import { useEffect } from "react";

const useThemeColor = (color: string) => {
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", color);
    } else {
      const meta = document.createElement("meta");
      meta.name = "theme-color";
      meta.content = color;
      document.head.appendChild(meta);
    }
    return () => {
      metaThemeColor!.setAttribute("content", "#fff");
      const meta = document.createElement("meta");
      meta.name = "theme-color";
      meta.content = "#fff";
      document.head.appendChild(meta);
    };
  }, [color]);
};

export default useThemeColor;
