import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Radio,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomDrawer from "./BottomDrawer";
import PaymentSuccess from "./PaymentSuccess";
import { useMutation } from "@tanstack/react-query";
import { initiatePayment, verifyPayment } from "../services/payment";
import { createBooking } from "../services/booking";
import toast from "react-hot-toast";
import { handleMutationError } from "../services/handler";
import { format } from "date-fns";

const SelectPayment = ({
  amount,
  bookingData,
  accountNumber,
  bankName,
  accountName,
  expiryT,
  transactionReference,
  showDetails,
}: {
  amount?: number;
  bookingData?: any;
  accountNumber?: any;
  bankName?: any;
  accountName?: any;
  expiryT?: any;
  transactionReference?: any;
  showDetails?: boolean;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);
  const [balance, setBalance] = useState<number | null>(amount || null);

  const [transactionId, setTransactionId] = useState<string | null>(null);

  const [bookingDate, setBookingDate] = useState<string | null>(null);
  const [bookingId, setBookingId] = useState<string | null>(null);
  const [busName, setBusName] = useState<string | null>(null);
  const [timeOfTravel, setTimeOfTravel] = useState<string | null>(null);
  const [seatNumber, setSeatNumber] = useState<string | null>(null);
  const [parkName, setParkName] = useState<string | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const [parkBusStop, setParkBusStop] = useState<string | null>(null);
  const [estimatedHours, setEstimatedHours] = useState<string | null>(null);
  const [stopLocation, setStopLocation] = useState<string | null>(null);

  const verifyTransferMutation = useMutation({
    mutationFn: async (transactionReference: any) =>
      await verifyPayment(transactionReference),
    onSuccess: (data) => {
      console.log("Payment verification successful:", data);
      const transactionId = data.data?.response?.transaction_id;
      console.log(transactionId);
      if (transactionId) {
        setTransactionId(transactionId);
        toast("Paid! - Successful");
        handleCreateBooking(transactionId);
      }
      //setOpen(true); // Open success drawer
    },
    onError: (error) => {
      console.error("Error verifying payment:", error);
      toast.error("Failed to verify payment. Please try again.");
    },
  });
  const bookingMutation = useMutation({
    mutationFn: async (transactionId: any) => {
      if (!transactionId) {
        throw new Error("Transaction ID is required");
      }
      return await createBooking({
        ...bookingData,
        transaction_id: transactionId?.toString(),
      });
    },
    onSuccess: (data) => {
      console.log("Booking created successfully:", data);

      const bookDate =
        data.data?.booking_response.bookingResponse.booking_details
          .booking_date;
      setBookingDate(bookDate);
      const bookId = data.data?.booking_response.bookingResponse.booking_id;
      setBookingId(bookId);
      const busN =
        data.data?.booking_response.bookingResponse.booking_details.busname;
      setBusName(busN);
      const tOfTravel =
        data.data?.booking_response.bookingResponse.booking_details
          .timeoftravel;
      setTimeOfTravel(tOfTravel);
      const seatNumb =
        data.data?.booking_response.bookingResponse.booking_details.seatnumber;
      setSeatNumber(seatNumb);
      const parkName =
        data.data?.booking_response.bookingResponse.booking_details.parkname;
      setParkName(parkName);
      const pric =
        data.data?.booking_response.bookingResponse.booking_details.price;
      setPrice(pric);
      const f =
        data.data?.booking_response.bookingResponse.booking_details.from;
      setFrom(f);
      const t = data.data?.booking_response.bookingResponse.booking_details.to;
      setTo(t);
      const parkBusStop =
        data.data?.booking_response.bookingResponse.booking_details
          .parkbus_stop;
      setParkBusStop(parkBusStop);
      const estimatedH =
        data.data?.booking_response.bookingResponse.booking_details
          .estimated_hours;
      setEstimatedHours(estimatedH);
      const stopL =
        data.data?.booking_response.bookingResponse.booking_details
          .stoplocation;
      setStopLocation(stopL);

      setOpen(true);
    },
    onError: (error) => {
      console.error("Error creating booking:", error);
      handleMutationError(error);
      console.log("Failed to create booking. Please try again.");
    },
  });
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleConfirmTransferClick = () => {
    if (!isCheckboxChecked) {
      toast.error("Please agree to the Terms and Privacy Policy to continue.");
      return;
    }
    if (transactionReference) {
      verifyTransferMutation.mutate(transactionReference);
    } else {
      console.log("No transaction reference available for verification.");
    }
  };

  const handleCreateBooking = (transactionId: any) => {
    bookingMutation.mutate(transactionId);
    console.log(transactionId, "inside func");
  };
  const expires = expiryT
    ? format(new Date(expiryT), "MMM dd yyyy HH:mm")
    : null;
  return (
    <>
      <BottomDrawer
        open={open}
        onClose={() => setOpen(false)}
        drawerHeight="100vh"
      >
        <PaymentSuccess
          bookingDate={bookingDate}
          bookingId={bookingId}
          busName={busName}
          timeOfTravel={timeOfTravel}
          seatNumber={seatNumber}
          parkName={parkName}
          price={price}
          from={from}
          to={to}
          parkBusStop={parkBusStop}
          stopLocation={stopLocation}
          estimatedHours={estimatedHours}
        />
      </BottomDrawer>
      <Box p={2} sx={{ scrollBehavior: "smooth" }}>
        <Box
          display={{ md: "flex", xs: "none" }}
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          bgcolor="#F6F6F6"
          p={1}
          height="42px"
        >
          <Typography fontWeight={600} fontSize={12}>
            Default Payment Methods
          </Typography>
        </Box>

        {showDetails ? (
          <Box p={2} display="grid" sx={{ placeItems: "center" }}>
            <Typography fontWeight={500} fontSize={16}>
              Pay NGN {balance?.toLocaleString()} to
            </Typography>
            <Typography fontWeight={700} fontSize={16} my={1}>
              {accountNumber}
            </Typography>
            <Typography fontWeight={500} fontSize={16}>
              {accountName} - {bankName}
            </Typography>
            <Typography
              fontWeight={500}
              fontSize={12}
              mx={3}
              textAlign="center"
            >
              This account is valid for this transaction until {expires}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          bgcolor={
            selectedPaymentMethod === "wallet" ? "#E8FFF2" : "transparent"
          }
          border={
            selectedPaymentMethod === "wallet"
              ? "1px solid #379E66"
              : "1px solid #E4E7EC"
          }
          borderRadius="8px"
          p={1}
          height="44px"
        >
          <Typography fontWeight={600} fontSize={12}>
            Bal: NGN {balance?.toLocaleString()}
          </Typography>
        </Box> */}
        <Box
          display="flex"
          alignItems="center"
          my={2}
          mt={{ md: "70%" }}
          mx={{ md: 8 }}
          justifyContent="start"
        >
          <Checkbox
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
          <Typography fontSize={12}>
            I agree to the{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigate("//terms&conditions")}
            >
              Terms
            </span>{" "}
            and{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </span>{" "}
            of this booking.
          </Typography>
        </Box>
        <Button
          sx={{ p: "10px 14px", borderRadius: "8px", mb: 2 }}
          fullWidth
          variant="contained"
          onClick={handleConfirmTransferClick}
          disabled={verifyTransferMutation.isPending}
        >
          {verifyTransferMutation.isPending ? "Verifying..." : "Verify Payment"}
        </Button>
      </Box>
    </>
  );
};

export default SelectPayment;
