import http from './http';

export const initiatePayment = async (body: any) => {
  const res = http.post('/booking/payment/initiatepayment', body);
  return (await res).data;
};

export const verifyPayment = async (transactionReference: string) => {
  const body = { transactionReference };
  const res = await http.post('/booking/payment/verifypayment', body);
  return res.data;
};

export const getTransactions = async () => {
  const res = await http.get('/customer/trips/get-trip-transactions');
  return res?.data;
};
