import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {
  Button,
  Card,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  keyframes,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, ArrowBack, Check } from "@mui/icons-material";
import { ProfileLayout } from "../ProfileLayout";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getMedicationInfo,
  uploadMedicationInfo,
} from "../../../services/user_settings";
import toast from "react-hot-toast";
import { handleMutationError } from "../../../services/handler";

const comeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  } `;

//this is to define the form validation using yup for the form inputs below
const schema = yup.object().shape({
  choronic_illness: yup.string().required("Input the chronic illness"),
  surgeries: yup.string().required("Input the surgeries"),
  allergies: yup.string().required("Input the allergies"),
  physical_condition: yup.string().required("Input the physical condition"),
  medication: yup.string().required("Input the medication"),
  instruction: yup.string().required("Input the instruction"),
  insurance_policy: yup
    .string()
    .required("Input the insurance policy provider"),
  policy_number: yup.string().required("Input the policy number"),
  insurance_contact: yup.string().required("Input the insurance contact"),
});

const healthDetails = [
  {
    title: "Chronic illnesses",
    value: "None",
  },
  { title: "Recent surgeries", value: "None" },
  { title: "Allergies", value: "None" },
  { title: "Physical Disabilities", value: "None" },
];
const healthMedications = [
  { title: "Current Medications", value: "None" },
  { title: "Specific Instructions", value: "None" },
];
const healthInsurance = [
  {
    title: "Insurance Provider",
    value: "None",
  },
  { title: "Policy Number", value: "None" },
  { title: "Insurance Company Contact", value: "None" },
];

export interface FormValues {
  choronic_illness: string;
  surgeries: string;
  allergies: string;
  physical_condition: string;
  medication: string;
  instruction: string;
  insurance_policy: string;
  policy_number: string;
  insurance_contact: string;
}

const HealthDetails = ({ onEditHealth, setOnEditHealth }: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [value, setValue] = React.useState("health");
  const handleChange = (value: string) => {
    setValue(value);
  };

  const mutation = useMutation({
    mutationFn: async (data: FormValues) => {
      return await uploadMedicationInfo(data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["HEALTH"] });
      console.log("Successfully uploaded🚀", data);
      toast.success("Saved Successfully!");
      setOnEditHealth(false);
    },
    onError: (err) => {
      console.log(err);
      handleMutationError(err);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  const { data: healthDetails } = useQuery({
    queryKey: ["HEALTH"],
    queryFn: getMedicationInfo,
  });

  // const healthInformation = healthDetails?.data?.result[0];
  // console.log(healthInformation, "dd");
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box display={onEditHealth ? "none" : "block"}>
          <Box>
            <Box mx="5%">
              <>
                <HealthDetail
                  title="Allergies"
                  //value={healthInformation.allergies}
                />
                <HealthDetail
                  title="Choronic Illness"
                  //value={healthInformation.choronic_illness}
                />
                <HealthDetail
                  title="Instruction"
                  //value={healthInformation.instruction}
                />

                <HealthDetail
                  title="Insurance Policy"
                  //value={healthInformation.insurance_policy}
                />
                <HealthDetail
                  title="Medication"
                  //value={healthInformation.medication}
                />

                <HealthDetail
                  title="Physical Condition"
                  //value={healthInformation.physical_condition}
                />
                <HealthDetail
                  title="Policy Number"
                  //value={healthInformation.policy_number}
                />

                <HealthDetail
                  title="Surgeries"
                  //value={healthInformation.surgeries}
                />
                <HealthDetail
                  title="Insurance Contact"
                  //value={healthInformation.insurance_contact}
                />
              </>
            </Box>
          </Box>
        </Box>
        {/* THE OTHER PART */}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display={onEditHealth ? "block" : "none"}
        >
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Chronic Illness
            </InputLabel>
            <Controller
              control={control}
              name="choronic_illness"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Diabetes, Asthma, Epilepsy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.choronic_illness}
                  helperText={
                    errors.choronic_illness
                      ? errors.choronic_illness.message
                      : ""
                  }
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Recent Surgeries
            </InputLabel>
            <Controller
              control={control}
              name="surgeries"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Caesarean Section, Mastectomy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.surgeries}
                  helperText={errors.surgeries ? errors.surgeries.message : ""}
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Allergies
            </InputLabel>
            <Controller
              control={control}
              name="allergies"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Asthma"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.allergies}
                  helperText={errors.allergies ? errors.allergies.message : ""}
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Physical Condition
            </InputLabel>
            <Controller
              control={control}
              name="physical_condition"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Epilepsy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.physical_condition}
                  helperText={
                    errors.physical_condition
                      ? errors.physical_condition.message
                      : ""
                  }
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Current Medication
            </InputLabel>
            <Controller
              control={control}
              name="medication"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Diabetes, Asthma, Epilepsy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.medication}
                  helperText={
                    errors.medication ? errors.medication.message : ""
                  }
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Specific Instructions
            </InputLabel>
            <Controller
              control={control}
              name="instruction"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Diabetes, Asthma, Epilepsy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.instruction}
                  helperText={
                    errors.instruction ? errors.instruction.message : ""
                  }
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Insurance Provider
            </InputLabel>
            <Controller
              control={control}
              name="insurance_policy"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Diabetes, Asthma, Epilepsy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.insurance_policy}
                  helperText={
                    errors.insurance_policy
                      ? errors.insurance_policy.message
                      : ""
                  }
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Policy Number
            </InputLabel>
            <Controller
              control={control}
              name="policy_number"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Diabetes, Asthma, Epilepsy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.policy_number}
                  helperText={
                    errors.policy_number ? errors.policy_number.message : ""
                  }
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Insurance Company Contact
            </InputLabel>
            <Controller
              control={control}
              name="insurance_contact"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Diabetes, Asthma, Epilepsy"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!errors.insurance_contact}
                  helperText={
                    errors.insurance_contact
                      ? errors.insurance_contact.message
                      : ""
                  }
                />
              )}
            />
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            sx={{ borderRadius: "8px", p: "12px 24px", mt: 2.5 }}
            type="submit"
            disabled={!!mutation.isPending}
          >
            {mutation.isPending ? "Saving..." : "Save Changes"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default HealthDetails;

const HealthDetail = ({ title, value }: { title: string; value?: string }) => {
  return (
    <Box mb={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography fontSize={12} fontWeight={600}>
          {title}
        </Typography>
        <Typography fontSize={12} color="#98A2B3">
          {value}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};
