import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import Calendar from "react-calendar";
import { format } from "date-fns";
import "react-calendar/dist/Calendar.css"; // Import default styles
import "./../index.css"; // Import custom styles

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export const getDateSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
//{format(selectedDate, 'yyyy-MM-dd')}

const TheCalender = ({
  onClick,
  selectedDate,
  setSelectedDate,
}: {
  onClick?: () => void;
  selectedDate?: Date;
  setSelectedDate?: (date: Date) => void;
}) => {
  const [value, onChange] = useState<Value>(new Date());

  const handleClickDay = (date: Date) => {
    if (setSelectedDate) {
      setSelectedDate(date);
    }
  };
  const formattedDate = (date: Date) => {
    const day = format(date, "d");
    const month = format(date, "MMM");
    const year = format(date, "yyyy");
    return `${day}${getDateSuffix(Number(day))} ${month}, ${year}`;
  };
  return (
    <Box>
      <Box p={2} display="grid" sx={{ placeItems: "center" }}>
        <Calendar
          onChange={onChange}
          value={value}
          onClickDay={handleClickDay}
          next2Label={null}
          prev2Label={null}
          view="month"
        />
      </Box>
      <Box px={2}>
        <Typography fontWeight={600}>
          <span color="#667085" style={{ fontWeight: "normal" }}>
            Date Selected :
          </span>{" "}
          {selectedDate ? formattedDate(selectedDate) : "No date selected"}
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{ fontWeight: 400, mt: 2, p: "10px 14px" }}
          onClick={onClick}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default TheCalender;
