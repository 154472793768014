import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../services/auth';

const useGetUser = () => {
  const { data } = useQuery({
    queryKey: ['GET_USER_PROFILE'],
    queryFn: getUserProfile,
  });

  return (
    JSON.parse(localStorage.getItem('user_details')!) ||
    data?.data?.profileDetails
  );
};

export default useGetUser;
