import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {
  Button,
  Card,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  keyframes,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, ArrowBack, Check } from "@mui/icons-material";
import { ProfileLayout } from "../ProfileLayout";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getEmergencyContact,
  uploadEmergencyContact,
} from "../../../services/user_settings";
import toast from "react-hot-toast";
import { handleMutationError } from "../../../services/handler";

const comeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  } `;

const emergencySchema = yup.object().shape({
  name: yup.string().required("Input the name"),
  contact: yup.string().required("Input the contact"),
});

export interface FormValues {
  choronic_illness: string;
  surgeries: string;
  allergies: string;
  physical_condition: string;
  medication: string;
  instruction: string;
  insurance_policy: string;
  policy_number: string;
  insurance_contact: string;
}

const EmergencyDetails = ({ onEditEmergency, setOnEditEmergency }: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const emergencyMutation = useMutation({
    mutationFn: async (data: any) => {
      return await uploadEmergencyContact(data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["EMERGENCY_CONTACT"] });
      console.log("Successfully uploaded🚀", data);
      toast.success("Saved Successfully!");
      setOnEditEmergency(false);
      reset();
    },
    onError: (err) => {
      handleMutationError(err);
      console.log(err);
    },
  });

  const {
    control: emergencyControl,
    handleSubmit: emergencyHandleSubmit,
    formState: { errors: theErrors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      contact: "",
    },
    resolver: yupResolver(emergencySchema),
  });
  const [saved, setSaved] = React.useState(false);

  const onEmergencySubmit = (data: any) => {
    emergencyMutation.mutate(data);
  };

  const { data: emergencyContact } = useQuery({
    queryKey: ["EMERGENCY_CONTACT"],
    queryFn: getEmergencyContact,
  });

  console.log(emergencyContact?.data?.result);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box display={onEditEmergency ? "none" : "block"}>
          <Box>
            <Box mx="5%">
              {emergencyContact?.data?.result.map((contact: any) => (
                <EmergencyContact
                  title={contact.name}
                  phone={contact.contact}
                />
              ))}
              <Box>
                <Box display="flex" alignItems="center" gap="4px" mb={1}>
                  <Add
                    sx={{
                      color: "#379E66",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#379E66",
                      cursor: "pointer",
                    }}
                    onClick={() => setOnEditEmergency(true)}
                  >
                    Add new
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          component="form"
          onSubmit={emergencyHandleSubmit(onEmergencySubmit)}
          display={onEditEmergency ? "block" : "none"}
        >
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Name
            </InputLabel>
            <Controller
              name="name"
              control={emergencyControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Jonny"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!theErrors.name}
                  helperText={theErrors.name ? theErrors.name.message : ""}
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Phone Number
            </InputLabel>
            <Controller
              name="contact"
              control={emergencyControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="+234"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2.5,
                    mb: 1,
                    py: 1,
                    px: 2,
                    borderRadius: "10px",
                  }}
                  error={!!theErrors.name}
                  helperText={theErrors.name ? theErrors.name.message : ""}
                />
              )}
            />
          </FormControl>

          <Button
            fullWidth
            variant="contained"
            sx={{ borderRadius: "8px", p: "12px 24px", mt: 2.5 }}
            type="submit"
            disabled={!!emergencyMutation.isPending}
          >
            {emergencyMutation.isPending ? "Saving..." : "Save Changes"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EmergencyDetails;

const EmergencyContact = ({
  title,
  phone,
}: {
  title: string;
  phone: string;
}) => {
  return (
    <Box mb={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography fontSize={12} fontWeight={600}>
          {title}
        </Typography>
        <Typography fontSize={12} color="#98A2B3">
          {phone}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};
