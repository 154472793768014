import { Avatar, Box, Button, ButtonGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Move9jaLogo from "../assets/move9ja.svg";
import { FiberManualRecord, Notifications, Search } from "@mui/icons-material";

import { ReactComponent as SettingsIcon } from "../assets/settings.svg";
import { ReactComponent as NotifyIcon } from "../assets/notify.svg";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  //#379E66
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="0px 32px"
      mx="5.6%"
    >
      <img src={Move9jaLogo} alt="" height="50.26px" width="162.75px" />
      <ButtonGroup>
        <Button
          variant="text"
          sx={{
            color: window.location.pathname === "/home" ? "#379E66" : "#344054",
            fontSize: "16px",
          }}
          startIcon={
            window.location.pathname === "/home" ? (
              <FiberManualRecord sx={{ color: "green" }} fontSize="small" />
            ) : (
              <FiberManualRecord
                sx={{ color: "transparent" }}
                fontSize="small"
              />
            )
          }
          onClick={() => handleButtonClick("/home")}
        >
          Home
        </Button>
        <Button
          variant="text"
          sx={{
            color:
              window.location.pathname === "/trips" ? "#379E66" : "#344054",
            fontSize: "16px",
          }}
          startIcon={
            window.location.pathname === "/trips" ? (
              <FiberManualRecord sx={{ color: "green" }} fontSize="small" />
            ) : (
              <FiberManualRecord
                sx={{ color: "transparent" }}
                fontSize="small"
              />
            )
          }
          onClick={() => handleButtonClick("/trips")}
        >
          Trips
        </Button>
        <Button
          variant="text"
          sx={{
            color:
              window.location.pathname === "/wallet" ? "#379E66" : "#344054",
            fontSize: "16px",
          }}
          startIcon={
            window.location.pathname === "/wallet" ? (
              <FiberManualRecord sx={{ color: "green" }} fontSize="small" />
            ) : (
              <FiberManualRecord
                sx={{ color: "transparent" }}
                fontSize="small"
              />
            )
          }
          onClick={() => handleButtonClick("/wallet")}
        >
          Wallet
        </Button>
        <Button
          variant="text"
          sx={{
            color:
              window.location.pathname === "/activities"
                ? "#379E66"
                : "#344054",
            fontSize: "16px",
          }}
          startIcon={
            window.location.pathname === "/activities" ? (
              <FiberManualRecord sx={{ color: "green" }} fontSize="small" />
            ) : (
              <FiberManualRecord
                sx={{ color: "transparent" }}
                fontSize="small"
              />
            )
          }
          onClick={() => handleButtonClick("/activities")}
        >
          Activities
        </Button>
      </ButtonGroup>
      <Box display="flex" alignItems="center">
        <TextField
          placeholder="Find a bus park"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            startAdornment: <Search sx={{ height: 20, width: 20 }} />,
          }}
          sx={{
            bgcolor: "#F9F9F9",
            py: 1,
            px: 2,
            borderRadius: "10px",
            width: "370px",
          }}
          type="text"
        />
        <SettingsIcon style={{ margin: "8px" }} />
        <NotifyIcon style={{ margin: "8px" }} />
        <Avatar
          onClick={() => navigate("/profile")}
          sx={{ cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
};

export default NavBar;
