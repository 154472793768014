import React from "react";
import CheckIcon from "../../assets/check-circle.svg";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const PopUp = () => {
  return (
    <Box padding={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Close fontSize="large" fontWeight={400} />
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <img src={CheckIcon} />
        <Typography fontSize={16} textAlign="center" mx={4}>
          We hope this information was very helpful for you
        </Typography>
        <Button
          variant="contained"
          sx={{ p: "10px 14px", borderRadius: "8px" }}
          fullWidth
        >
          Close
        </Button>
      </div>
    </Box>
  );
};

export default PopUp;
