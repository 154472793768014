import React from "react";
import useCalendar from "../hooks/useCalendar";
import { format, isToday } from "date-fns";
import { Typography } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";

const Calendar = ({ onClick }: { onClick?: () => void }) => {
  const { currentMonth, days } = useCalendar();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {days.map((date: Date) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 0,
            }}
          >
            <Typography
              color={isToday(date) ? "green" : "#98A2B3"}
              fontSize="12px"
              fontWeight={700}
              sx={{ textTransform: "uppercase" }}
            >
              {isToday(date) ? "Today" : format(date, "EEE")}
            </Typography>
            <div
              key={date.toString()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                textAlign: "center",
                lineHeight: "40px",
                color: isToday(date) ? "green" : "gray",
              }}
            >
              <Typography
                fontWeight={600}
                fontSize="10px"
                sx={{ textTransform: "uppercase" }}
              >
                {format(date, "d")}
              </Typography>
              <Typography
                fontWeight={600}
                fontSize="10px"
                sx={{ textTransform: "uppercase" }}
              >
                {currentMonth}
              </Typography>
            </div>
          </div>
        ))}
        <CalendarMonth onClick={onClick} sx={{ cursor: "pointer" }} />
      </div>
    </div>
  );
};

export default Calendar;
