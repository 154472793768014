// src/hooks/useUser.ts

import { useEffect, useState } from "react";
import { getUserFromToken } from "../utils/decodeToken";

interface UserDetails {
  userId: string;
  username: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  role: string;
}

export const useUser = () => {
  const [user, setUser] = useState<UserDetails | null>(null);

  useEffect(() => {
    const userDetails = getUserFromToken();
    setUser(userDetails);
  }, []);

  return user;
};
