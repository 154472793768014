import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircleTick from "./../../assets/check-circle.svg";

export const FeedbackRecieved = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "85vh",
        width: "100wh",
        backgroundColor: "white",
        display: "grid",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
      p={2}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <img src={CircleTick} style={{ height: 64, width: 64 }} />
        <Typography fontWeight={600} fontSize={20}>
          Feedback recieved
        </Typography>
        <Typography px={4} textAlign="center" fontWeight={500} fontSize={14}>
          We are sorry to hear that you weren’t happy with the service you
          received! Thank you for reporting this to us. We promise to take
          necessary action to maintain high standard of service.
        </Typography>
      </Box>
      <Button
        sx={{ color: "#fff", p: "12px 24px 12px 24px", borderRadius: "8px" }}
        variant="contained"
        onClick={() => {
          navigate("/home");
        }}
      >
        Okay, Go Home
      </Button>
    </Box>
  );
};
