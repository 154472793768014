import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { createBeneficiary } from '../services/user_settings';
import toast from 'react-hot-toast';
import { handleMutationError } from '../services/handler';
import { formatPhoneNumber } from '../utils/misc';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  address: string;
}

const schema = yup.object().shape({
  first_name: yup.string().required('first name is required'),
  last_name: yup.string().required('last name is required'),
  email: yup.string().email('Email is required').required('email is required'),
  contact: yup
    .string()
    .required('Contact is required')
    .length(10, 'Contact must be exactly 10 characters long'),
  address: yup.string().required('address is required'),
});

const NewBeneficiary = ({
  searchResults,
  selectedResult,
  selectedSeat,
}: any) => {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (data: FormValues) => {
      return await createBeneficiary({
        ...data,
        contact: formatPhoneNumber(data?.contact),
      });
    },
    onSuccess: (data) => {
      console.log(data);
      toast.success('Beneficiary Saved!');
      navigate('/booking-details', {
        state: { searchResults, selectedResult, selectedSeat },
      });
    },
    onError: (err) => {
      handleMutationError(err);
      console.log(err);
    },
  });

  const onSubmit = (data: FormValues) => {
    mutation.mutate(data);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Box p={2} position='relative'>
        <Typography fontWeight={700} fontSize={20} mb={2}>
          New Beneficiary
        </Typography>

        <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800 }}>
                First Name
              </InputLabel>
              <Controller
                name='first_name'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='John'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2.5,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.first_name}
                    helperText={
                      errors.first_name ? errors.first_name.message : ''
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800 }}>
                Last Name
              </InputLabel>
              <Controller
                name='last_name'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Doe'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      borderRadius: '10px',
                      borderStyle: 'none',
                      mt: 2.5,
                      py: 1,
                      px: 2,
                    }}
                    error={!!errors.last_name}
                    helperText={
                      errors.last_name ? errors.last_name.message : ''
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
          <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Email Address
            </InputLabel>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder='johndoe@gmail.com'
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2.5,
                    mb: 2,
                    py: 1,
                    px: 2,
                    borderRadius: '10px',
                  }}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              )}
            />
          </FormControl>

          <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Phone Number
            </InputLabel>
            <Controller
              name='contact'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder='9025738297'
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position='start'>+234</InputAdornment>
                    ),
                  }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2.5,
                    mb: 2,
                    py: 1,
                    px: 2,
                    borderRadius: '10px',
                  }}
                  error={!!errors.contact}
                  helperText={errors.contact ? errors.contact.message : ''}
                />
              )}
            />
          </FormControl>
          <FormControl variant='standard' fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Address
            </InputLabel>
            <Controller
              name='address'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder='123 Main Street'
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2,
                    borderRadius: '10px',
                    borderStyle: 'none',
                    py: 1,
                    px: 2,
                  }}
                  error={!!errors.address}
                  helperText={errors.address ? errors.address.message : ''}
                />
              )}
            />
          </FormControl>

          <Button
            disabled={!!mutation.isPending}
            variant='contained'
            color='primary'
            fullWidth
            sx={{
              fontWeight: 400,
              p: '10px 14px',
              borderRadius: 2,
              mt: 8,
              mb: 2,
            }}
            type='submit'
          >
            {mutation.isPending ? 'Creating...' : 'Continue'}
          </Button>
        </form>
      </Box>
    </>
  );
};

export default NewBeneficiary;
