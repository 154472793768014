import { Box, Button, Card, Chip, Radio, Typography } from '@mui/material';

import Car from './../assets/car.png';
import Sienna from './../assets/sienna.png';
import SmallBus from './../assets/small-bus.png';

import Round from './../assets/round.png';
import Exclusive from './../assets/exclusive.svg';

import { useEffect, useState } from 'react';
import Move9jaCard from './Move9jaCard';
import { ArrowDropDown } from '@mui/icons-material';
import BottomDrawer from './BottomDrawer';
import SelectSeatUI from './SelectSeat';

export const Filter = ({ searchResults, selectedResult }: any) => {
  console.log('Selected Result', selectedResult);
  const [openSelectSeat, setOpenSelectSeat] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  useEffect(() => {
    const carIndex = vehicles.findIndex(
      (item) => item.title === selectedResult.car_type
    );
    if (carIndex !== -1) {
      setSelected(carIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);

  const onClickCard = (index: number) => {
    setSelected(index);
  };
  const [selectedOption, setSelectedOption] = useState(selectedResult.choice);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };
  return (
    <>
      <Box p={2}>
        <Move9jaCard title='Select Vehicle'>
          <Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap={1} m={1}>
            {vehicles.map((item, index) => (
              <Card
                key={index}
                elevation={2}
                sx={
                  selected === index
                    ? {
                        p: 1,
                        bgcolor: '#E8FFF2',
                        position: 'relative',
                        cursor: 'pointer',
                      }
                    : {
                        p: 1,
                        cursor: 'pointer',
                      }
                }
                onClick={() => onClickCard(index)}
              >
                <img
                  src={item.img}
                  style={{ objectFit: 'contain' }}
                  alt=''
                  height={74}
                  width={74}
                />
                <Typography fontSize={12}>{item.title}</Typography>
                {selected === index ? (
                  <Box
                    position='absolute'
                    top={4}
                    left={4}
                    border='2px solid green'
                    height='20px'
                    width='20px'
                    borderRadius='100%'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Box
                      border='2px solid green'
                      bgcolor='green'
                      height='12px'
                      width='12px'
                      borderRadius='100%'
                    />
                  </Box>
                ) : (
                  ''
                )}
              </Card>
            ))}
          </Box>
        </Move9jaCard>
        <Move9jaCard title='Price'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p={1}
            m={1}
            border='1px solid #F2F4F7'
            borderRadius='8px'
          >
            <Typography>NGN {selectedResult.price}</Typography>
            <ArrowDropDown />
          </Box>
        </Move9jaCard>
        <Box display='grid' gridTemplateColumns='1fr 1fr' gap='12px'>
          <Move9jaCard title='Departure Time'>
            <Box
              p={2}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography>{selectedResult.schedule.timeoftravel}</Typography>
              <ArrowDropDown />
            </Box>
          </Move9jaCard>
          <Move9jaCard title='Available Seats'>
            <Box
              p={2}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography>
                {selectedResult.available_seats} seats left
              </Typography>
              <ArrowDropDown />
            </Box>
          </Move9jaCard>
        </Box>
        <Move9jaCard title="Traveller's Chioce">
          <Box p={1}>
            <Box display='flex' alignItems='flex-start' gap='4px'>
              <Radio
                checked={selectedOption === 'Exclusive'}
                onChange={() => handleOptionChange('Exclusive')}
              />
              <Box>
                <Chip
                  sx={{
                    bgcolor: '#FDF2FA',
                    color: '#C11574',
                    fontWeight: 600,
                  }}
                  label='Exclusive'
                  size='small'
                  icon={
                    <img
                      src={Exclusive}
                      alt='exclusive'
                      height='12px'
                      width='12px'
                    />
                  }
                />
                <Typography color='#667085' fontSize='12px'>
                  TVs, movies, music , Wi-Fi and power outlets
                </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='flex-start' gap='4px' mt={1}>
              <Radio
                checked={selectedOption === 'Standard'}
                onChange={() => handleOptionChange('Standard')}
              />
              <Box>
                <Chip
                  sx={{
                    bgcolor: '#EEF4FF',
                    color: '#3538CD',
                    fontWeight: 600,
                  }}
                  label='Standard'
                  size='small'
                  icon={
                    <img src={Round} alt='round' height='12px' width='12px' />
                  }
                />
                <Typography color='#667085' fontSize='12px'>
                  Regular seating with limited legroom s
                </Typography>
              </Box>
            </Box>
          </Box>
        </Move9jaCard>
        <Button
          fullWidth
          variant='contained'
          sx={{ borderRadius: '8px', p: '12px 24px', mt: 3 }}
          onClick={() => setOpenSelectSeat(true)}
        >
          Apply Filter
        </Button>
      </Box>
      <BottomDrawer
        open={openSelectSeat}
        onClose={() => setOpenSelectSeat(false)}
        secondDesign
        secondDesignTitle='Select Seat'
        drawerHeight='55vh'
      >
        <SelectSeatUI
          searchResults={searchResults}
          selectedResult={selectedResult}
        />
      </BottomDrawer>
    </>
  );
};

const vehicles = [
  { title: 'Car', img: Car },
  { title: 'Sienna', img: Sienna },
  { title: 'Bus', img: SmallBus },
];

const someData = [
  { title: 'Date', info: 'Jun 10, 2024' },

  { title: 'Time', info: '12:00 AM' },
  { title: 'Repeats', info: 'Every Day' },
  { title: 'Seats', info: '14 seats' },
  { title: 'Chioce', info: 'Luxury' },
];
