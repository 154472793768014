import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }: { children: ReactNode }) => {
  if (!localStorage.getItem('token')) {
    return <Navigate to='/login' />;
  }
  return <>{children}</>;
};

export default AuthGuard;
