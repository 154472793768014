import React, { createContext, useState } from "react";

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };


  return (
    <SearchContext.Provider
      value={{ searchValue, handleSearchChange, setSearchValue }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
