import { ArrowBack } from "@mui/icons-material";
import { Typography, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <Box height="100vh" mx={{ md: "14%" }}>
        <Box px={1} py={2}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              About Us
            </Typography>
          </Box>
          <Box px={2}>
            <Typography mb={1} fontSize={16}>
              Welcome to MOVE9JA, Nigeria's go-to transport booking platform.
              We're here to make traveling across Nigeria safe, easy, and
              affordable.
            </Typography>
            <Typography mb={1} fontSize={16}>
              Our journey started with a simple goal: connecting passengers with
              reliable transport options using technology. Now, with our app,
              you can book private and public buses effortlessly, avoiding long
              waits at the park and enjoying a smooth, secure ride.
            </Typography>
            <Typography mb={1} fontSize={16}>
              At MOVE9JA, we care deeply about our customers, drivers, and
              partners. We're dedicated to providing great service, building a
              supportive community, and bringing new ideas to the transport
              industry.
            </Typography>
            <Typography mb={1} fontSize={16}>
              Join us in transforming transportation in Nigeria. Download our
              app today and discover the convenience!
            </Typography>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default AboutUs;
