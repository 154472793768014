import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ParkManagerDetails from "../shared/ParkManagerDetails";
import Move9jaCard from "../shared/Move9jaCard";
import CustomTimeline from "../shared/TravelLocationTimeline";
import CheckIcon from "../assets/check-circle.svg";
import { ArrowBack, ArrowUpward, Close } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Move9jaTravelTimeline from "../shared/Move9jaTravelTimeline";
import TravelerDetails from "../shared/TravelerDetails";
import BottomDrawer from "../shared/BottomDrawer";
import SelectPayment from "../shared/SelectPayment";
import DashboardLayout from "../layouts/DashboardLayout";
import NewBeneficiary from "../shared/NewBeneficiary";
import { format } from "date-fns";
import { getDateSuffix } from "../shared/TheCalender";
import useGetUser from "../hooks/useGetUser";
import { useMutation } from "@tanstack/react-query";
import { initiatePayment } from "../services/payment";
import toast from "react-hot-toast";

const Summary = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const location = useLocation();
  const { bookingData, searchResults, selectedResult, selectedSeat } =
    location.state || {}; // Retrieve bookingData

  const { travel_date } = bookingData || {};
  console.log(travel_date);
  const { totalPrice } = location.state || {}; //retrieve total_price from the bookingData object

  const { busFee } = location.state || {}; //retrieved from booking details from the search api results
  const grandTotal = busFee + 50 + totalPrice;

  const breakdown = [
    {
      title: "Bus Fee",
      amount: busFee,
    },
    {
      title: "Add-Ons",
      amount: totalPrice,
    },
    {
      title: "VAT",
      amount: 50,
    },
    {
      title: "Grand Total",
      amount: grandTotal,
    },
  ];

  console.log("Location xx", location?.state);

  const formattedDate = (date: Date) => {
    const day = format(date, "d");
    const month = format(date, "MMM");
    return `${day}${getDateSuffix(Number(day))} ${month}`;
  };

  const { email, firstName, lastName, phoneNumber } = useGetUser();
  console.log("GEtuser", useGetUser());

  const [accountNumber, setAccountNumber] = useState<string | null>(null);
  const [accountName, setAccountName] = useState<string | null>(null);
  const [expiryTime, setExpiryTime] = useState<string | null>(Date);
  const [bankName, setBankName] = useState<string | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const [transactionReference, setTransactionReference] = useState<
    string | null
  >(null);

  const transferMutation = useMutation({
    mutationFn: async (data: any) => await initiatePayment(data),
    onSuccess: (data) => {
      console.log("Transfer initiated successfully----------:", data);
      const accountNumber = data.data?.bankdetails?.accountNumber;
      const bankName = data.data?.bankdetails?.bankName;
      const accountName = data.data?.bankdetails?.accountName;
      const expiryT = data.data?.bankdetails?.expiresOn;
      const transactionReference = data.data?.bankdetails?.transactionReference;

      if (accountNumber) {
        setAccountNumber(accountNumber);
        setAccountName(accountName);
        setBankName(bankName);
        setShowDetails(true);
        setExpiryTime(expiryT);
      }
      if (transactionReference) {
        setTransactionReference(transactionReference);
      }
    },
    onError: (error) => {
      console.error("Error initiating transfer:", error);
      toast.error("Failed to initiate transfer. Please try again.");
    },
  });

  const handleContinueClick = () => {
    const paymentData = {
      amount: grandTotal?.toString(),
    };

    transferMutation.mutate(paymentData);
  };

  return (
    <DashboardLayout>
      <BottomDrawer
        open={open}
        onClose={() => setOpen(false)}
        secondDesign
        secondDesignTitle="Proceed with your payment"
        drawerHeight="60vh"
      >
        <SelectPayment
          amount={grandTotal}
          bookingData={bookingData}
          accountName={accountName}
          accountNumber={accountNumber}
          bankName={bankName}
          expiryT={expiryTime}
          showDetails={!transferMutation.isPending}
          transactionReference={transactionReference}
        />
      </BottomDrawer>
      <BottomDrawer
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        defaultDesign
      >
        <NewBeneficiary />
      </BottomDrawer>
      <Box mx={{ md: "14%" }}>
        <Box
          height="168px"
          bgcolor="#000"
          color="#FFF"
          display={{ md: "flex", xs: "none" }}
          alignItems="center"
          justifyContent="space-between"
          px={2}
        >
          <Box>
            <Typography fontSize="20px" fontWeight={700}>
              {selectedResult.from} - {selectedResult.to}
            </Typography>
            <Typography fontSize="14px" fontWeight={400}>
              {searchResults.length} parks result (s)
            </Typography>
            <Typography fontSize="14px" fontWeight={400}>
              {formattedDate(new Date())}
            </Typography>
          </Box>
          <Box display="flex" alignItems="end" flexDirection="column">
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/home")}
            />
            <Typography height="25px" />
            <Typography
              fontSize="14px"
              fontWeight={400}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigate("/booking")}
            >
              Modify search
            </Typography>
          </Box>
        </Box>
        <Box mt={2} px={2} display="flex" alignItems="center">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography
            ml={1}
            fontSize="20px"
            fontWeight={700}
            onClick={() => {
              setOpen(true);
            }}
          >
            Summary
          </Typography>
        </Box>
        <Box
          px={2}
          display={{ xs: "block", md: "grid" }}
          sx={{ gridTemplateColumns: "45% 55%", gap: "16px" }}
        >
          <Box>
            <Move9jaCard title="Thu, May 23, 2024" endText="6h 40m">
              <CustomTimeline
                to={selectedResult.to}
                from={selectedResult.from}
                startTime={selectedResult.schedule.timeoftravel}
                seat={selectedSeat}
                carModel={selectedResult.car_model}
                parkName={selectedResult.parkName}
              />
            </Move9jaCard>
          </Box>
          <Box>
            <Move9jaCard title="Traveller's Details">
              <Box m={1}>
                <TravelerDetails
                  name={firstName + " " + lastName}
                  onClick={() => setOpenDetails(true)}
                />
                <Box p={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <Typography fontSize="12px" color="#98A2B3">
                      Phone Number
                    </Typography>
                    <Typography fontSize="12px">+ {phoneNumber}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography fontSize="12px" color="#98A2B3">
                      Email
                    </Typography>
                    <Typography fontSize="12px">{email}</Typography>
                  </Box>
                </Box>
              </Box>
            </Move9jaCard>
            <Move9jaCard title="Discount">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                m={1}
              >
                <TextField
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  placeholder="Enter code"
                />
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontWeight={600}
                  color="#3E7EFF"
                  fontSize="12px"
                >
                  Apply code
                </Typography>
              </Box>
            </Move9jaCard>
            <Move9jaCard title="Breakdown">
              <Box m={1}>
                {breakdown.map(({ title, amount }, i) => (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={1}
                    >
                      <Typography fontSize="12px" fontWeight={400}>
                        {title}
                      </Typography>
                      <Typography fontSize="12px" fontWeight={600}>
                        N{amount}
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                ))}
              </Box>
            </Move9jaCard>
            <Button
              fullWidth
              sx={{ borderRadius: "8px", p: "10px 14px", mt: 2 }}
              variant="contained"
              onClick={() => {
                setOpen(true);
                handleContinueClick();
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Summary;
