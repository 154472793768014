import { ArrowBack, Check } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { ProfileLayout } from "./ProfileLayout";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <ProfileLayout>
      <Box mx="5%">
        <Box pt={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Back
            </Typography>
          </Box>
        </Box>
        <Typography mt={1} mb={3} fontSize={16}>
          Privacy Policy
        </Typography>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Effective Date: 19, Jan 24
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            At Move9ja, we value the privacy of our customers and are committed
            to protecting their personal information. This privacy policy
            explains how we collect, use, and disclose personal information from
            our customers.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Information We Collect
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Contact information (name, email address, phone number).
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Payment information (credit card number, expiration date).
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Location information (address, city, state).
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Trip booking information (destination, dates, itinerary).
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            How We Use Your Information
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - To process and confirm your trip bookings. - To provide customer
            support and respond to your inquiries.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - To send you important notices about your trip (e.g. confirmation
            emails, itinerary changes).
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - To improve our services and provide a better experience for our
            customers.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - To comply with legal and regulatory requirements (IF NEED BE).
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            How We Share Your Information
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - With our Transport / bus parks partners to facilitate your trip.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - With third-party service providers (e.g. payment processors to
            complete our business operations).
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - With legal and regulatory authorities to comply with applicable
            laws and regulations (IF NEED BE ).
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Data Security
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - We use industry-standard encryption to protect your personal
            information.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - We implement appropriate security measures to prevent unauthorized
            access to your information.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - We ensure that our partners and suppliers have similar data
            security measures in place.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Your Rights
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - You have the right to access and correct your personal
            information.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - You have the right to request deletion of your personal
            information.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - You have the right to object to processing of your personal
            information.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Changes to This Privacy Policy
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - We may update this privacy policy from time to time.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - We will notify you of any significant changes by posting a notice
            on our website or sending you an email.
          </Typography>
        </Box>
        <Box height="200px" />
        <Button variant="contained" fullWidth sx={{ fontWeight: 400 }}>
          Contact Us
        </Button>
        <Typography fontSize="12px" textAlign="center" mt="12px" mb="24px">
          By using Move9ja's services, you consent to the collection, use, and
          disclosure of your personal information as described in this privacy
          policy.
        </Typography>
      </Box>
    </ProfileLayout>
  );
};

export default PrivacyPolicy;
