import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  keyframes,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowBack, ErrorOutline } from '@mui/icons-material';
import move9jaLogo from '../../assets/move9ja.svg';
import move9jaBoy from '../../assets/move9jaBoy.png';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { IUser, completeRegistration } from '../../services/auth';
import { convertKeysToSnakeCase, formatPhoneNumber } from '../../utils/misc';
import { useEffect, useState } from 'react';
import { handleMutationError } from '../../services/handler';

const errorComes = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const schema = yup.object({
  first_name: yup.string().required('First name is requried'),
  last_name: yup.string().required('Last name is requried'),
  username: yup.string().required('Username is requried'),
  dob: yup.date().required('Date of birth is requried'),
  phone: yup
    .string()
    .required('Phone number is required')
    .length(10, 'Phone number must be exactly 10 characters long'),
  email: yup.string(),

  type: yup.string().oneOf(['email']).required(),
});

const CompleteRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const mutation = useMutation({
    mutationFn: async (data: IUser) => {
      return await completeRegistration(data);
    },
    onSuccess: (data) => {
      console.log('on success', data);
      if (data?.data?.token) {
        localStorage.setItem('token', data?.data?.token);
        localStorage.setItem(
          'user_details',
          JSON.stringify(data?.data?.user_details)
        );
      }
      navigate('/profile-completed', {
        state: { name: data?.data?.user_details?.first_name },
      });
    },
    onError: (err) => {
      handleMutationError(err);
      // setError(true);
      console.log(err);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: 'email',
    },
  });

  const onSubmit = (data: FieldValues) => {
    const date = new Date(data?.dob);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { email, ...restData } = data;
    const datVal = {
      ...(restData as Omit<IUser, 'dob'>),
      dob: formattedDate,
      user_id: (location as any)?.state?.userId,
      phone: formatPhoneNumber(data?.phone),
    };

    console.log('On submit data', datVal);
    mutation.mutate(convertKeysToSnakeCase({ ...datVal }));
  };

  return (
    <Box height='100vh'>
      <Box
        height='72px'
        p='0px 80px'
        display={{ xs: 'none', md: 'flex' }}
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
        <Button
          sx={{
            bgcolor: '#DA4733',
            width: '109px',
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '14px',
          }}
          variant='contained'
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'grid' },
          gridTemplateColumns: '40% 60%',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${move9jaBoy})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            height: '952px',
            width: '100%',
            margin: 0,
            padding: 0,
            display: { xs: 'none', md: 'grid' },
            placeItems: 'end',
          }}
        >
          <Typography
            color='#fff'
            fontSize='32px'
            fontWeight={600}
            height='92px'
            width='324px'
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display='flex'
          height={{ md: '100vh' }}
          alignItems='center'
          flexDirection='column'
        >
          <Box
            width={{ xs: '100%', md: '510px' }}
            p='0px 24px'
            mt={{ md: '70px', xs: '40px' }}
          >
            <Box display='flex' alignItems='center' gap={1} mb='12px'>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={800} fontSize={20}>
                Complete Registration
              </Typography>
            </Box>
            <Typography mb={{ md: '42px', xs: '32px' }}>
              Complete profile details correctly
            </Typography>
            <form
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                gap={2}
              >
                <FormControl variant='standard' fullWidth>
                  <InputLabel shrink sx={{ fontWeight: 800 }}>
                    First Name
                  </InputLabel>
                  <Controller
                    name='first_name'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder='John'
                        type='text'
                        variant='standard'
                        InputProps={{ disableUnderline: true }}
                        sx={{
                          bgcolor: '#F9F9F9',
                          borderRadius: '10px',
                          borderStyle: 'none',
                          mt: 2.5,
                          py: 1,
                          px: 2,
                        }}
                        error={!!errors.first_name}
                        helperText={errors.first_name?.message}
                      />
                    )}
                  />
                </FormControl>
                <FormControl variant='standard' fullWidth>
                  <InputLabel shrink sx={{ fontWeight: 800 }}>
                    Last Name
                  </InputLabel>
                  <Controller
                    name='last_name'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder='Doe'
                        variant='standard'
                        InputProps={{ disableUnderline: true }}
                        sx={{
                          bgcolor: '#F9F9F9',
                          borderRadius: '10px',
                          borderStyle: 'none',
                          mt: 2.5,
                          py: 1,
                          px: 2,
                        }}
                        error={!!errors.last_name}
                        helperText={
                          errors.last_name ? errors.last_name.message : ''
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                <InputLabel shrink sx={{ fontWeight: 800 }}>
                  Username
                </InputLabel>
                <Controller
                  name='username'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Create a username'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        mt: 2.5,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: '10px',
                      }}
                      error={!!errors.username}
                      helperText={
                        errors.username ? errors.username.message : ''
                      }
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Date of Birthday <span>(optional)</span>
                </InputLabel>
                <Controller
                  name='dob'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='DD-MM-YYYY'
                      type='date'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        mt: 2,
                        borderRadius: '10px',
                        borderStyle: 'none',
                        py: 1,
                        px: 2,
                      }}
                      error={!!errors.dob}
                      helperText={errors.dob ? errors.dob.message : ''}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                <InputLabel shrink sx={{ fontWeight: 800 }}>
                  Phone Number
                </InputLabel>
                <Controller
                  name='phone'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='+234'
                      variant='standard'
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position='start'>+234</InputAdornment>
                        ),
                      }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        mt: 2.5,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: '10px',
                      }}
                      error={!!errors.phone}
                      helperText={errors.phone ? errors.phone.message : ''}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Email Address
                </InputLabel>
                <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter a valid email'
                      type='email'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        mt: 2,
                        borderRadius: '10px',
                        borderStyle: 'none',
                        py: 1,
                        px: 2,
                      }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                    />
                  )}
                />
              </FormControl>
              <Button
                disabled={mutation.isPending}
                type='submit'
                variant='contained'
                fullWidth
                sx={{
                  fontWeight: 400,
                  borderRadius: '8px',
                  p: '10px 16px',
                  mt: '14px',
                }}
              >
                {mutation.isPending ? 'Completing...' : 'Continue'}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>

      {error && (
        <Card
          variant='elevation'
          sx={{
            backgroundColor: '#DA4733',
            color: 'white',
            width: '100%',
            animation: `${errorComes} 2s ease-in-out`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            position: 'absolute',
            top: 0,
            borderRadius: 0,
            p: 1,
          }}
        >
          <ErrorOutline sx={{ color: 'white' }} />
          <Typography fontSize={14}>{mutation.error?.message}</Typography>
        </Card>
      )}
    </Box>
  );
};

export default CompleteRegistration;
