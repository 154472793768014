import { Box, Button, TextField, Typography } from "@mui/material";
import smallTick from "./../assets/smallTick.svg";
import React from "react";
import BottomDrawer from "./BottomDrawer";
import Delete from "./Delete";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { deleteAccount } from "../services/user_settings";
import toast from "react-hot-toast";
import { handleMutationError } from "../services/handler";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  otp: yup.string().required("Otp required to delete account"),
});

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return await deleteAccount(data);
    },
    onSuccess: (data) => {
      console.log(data);
      toast.success("Account Delete Success!");
      localStorage.remove("token");
      localStorage.remove("user_details");
      navigate("/login");
    },
    onError: (err) => {
      handleMutationError(err);
      console.log(err);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };
  return (
    <>
      <BottomDrawer
        drawerHeight="50vh"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        defaultDesign
      >
        <Delete />
      </BottomDrawer>
      <Box p={2}>
        <Typography
          py={1}
          variant="body2"
          fontSize="24px"
          textAlign="left"
          fontWeight={700}
        >
          Are you sure you want to delete your Move9ja account?
        </Typography>
        <Typography fontSize={12} fontWeight={400} color="#667085">
          This action is permanent and will:
        </Typography>
        <Box mb={3}>
          <Box>
            {stuff.map(({ title }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  p: 1,
                  my: 1,
                }}
              >
                <img
                  src={smallTick}
                  alt="small-tick"
                  height="28px"
                  width="28px"
                />
                <Typography fontSize="15px" fontWeight={400}>
                  {title}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box display="grid" sx={{ placeContent: "center" }}>
            <Typography textAlign="center" fontSize="16px">
              To confirm, please enter the OTP code sent to your number or
              email:
            </Typography>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ borderRadius: "8px", padding: "12px 24px" }}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="18px"
          mt="25%"
          mb={1}
        >
          <Button
            sx={{
              bgcolor: "#F04438",
              color: "#FFF",
              p: "12px 35px",
              borderRadius: "20px",
              fontWeight: 600,
              fontSize: "12px",
              width: "112px",
            }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            YES
          </Button>

          <Button
            sx={{
              bgcolor: "#379E66",
              width: "112px",
              color: "#FFF",
              p: "12px 35px",
              borderRadius: "20px",
              fontWeight: 600,
              fontSize: "12px",
            }}
            variant="contained"
          >
            No
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeleteAccount;

const stuff = [
  { title: "Cancel all active bookings" },
  { title: "Terminate account access" },
  { title: " Permanently remove your data and history" },
];
