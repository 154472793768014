import {
  ArrowBack,
  Check,
  ContentCopy,
  ErrorOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Tab,
  Typography,
} from "@mui/material";
import masterCard from "../assets/mastercard.svg";
import AddIcon from "@mui/icons-material/Add";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import React from "react";
import { keyframes } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const Cards = () => {
  const [created, setCreated] = React.useState(false);

  const [value, setValue] = React.useState("activities");

  const handleChange = (value: string) => {
    setValue(value);
  };

  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <Box p={{ md: 2 }} mx={{ md: "14%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 2,
          }}
          m={2}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={800} fontSize={20}>
            Cards
          </Typography>
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }} p={2}>
          <Box
            border="1px solid #E4E7EC"
            bgcolor="#F9FAFB"
            borderRadius="8px"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            sx={{ placeItems: "center", cursor: "pointer" }}
            mb={2}
            width={{ xs: "100%", md: "342px" }}
          >
            <Typography
              sx={{
                border: value === "activities" ? "1px solid #E4E7EC" : "",
                bgcolor: value === "activities" ? "white" : "#F9FAFB",
                borderRadius: "8px",
                p: value === "activities" ? "8px 12px" : "",
                width: value === "activities" ? "100%" : "",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
              }}
              fontWeight={600}
              fontSize={14}
              onClick={() => handleChange("activities")}
            >
              Activities
            </Typography>
            <Typography
              sx={{
                border: value === "card_details" ? "1px solid #E4E7EC" : "1px",
                bgcolor: value === "card_details" ? "white" : "#F9FAFB",
                borderRadius: "8px",
                p: value === "card_details" ? "8px 12px" : "",
                width: value === "card_details" ? "100%" : "",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
              }}
              fontWeight={600}
              fontSize={14}
              onClick={() => handleChange("card_details")}
            >
              Card details
            </Typography>
          </Box>
          <Box>
            {value === "activities" && (
              <Box
                display={{ xs: "block", md: "grid" }}
                sx={{ gridTemplateColumns: "45% 55%", gap: "12px" }}
              >
                <Box>
                  <Card
                    sx={{
                      height: "180px",
                      borderRadius: "18.95px",
                      bgcolor: "#379E66",
                      p: 2,
                    }}
                    elevation={0}
                  >
                    <Box mb={3}>
                      <Typography color="white" fontSize={15.16}>
                        Move9ja
                      </Typography>
                      <Typography color="white" fontSize={30} fontWeight={700}>
                        $0.00
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Typography
                          color="white"
                          fontSize={11.37}
                          fontWeight={700}
                          mb={1}
                        >
                          OLIVIA RHYE
                        </Typography>
                        <Typography
                          color="white"
                          fontSize={15.16}
                          fontWeight={400}
                        >
                          **** 1234
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize={11.37}
                          mb={1}
                        >
                          06/24
                        </Typography>
                        <Box>
                          <img
                            src={masterCard}
                            alt=""
                            height="36px"
                            width="36px"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                  <Box display="grid" gap={1} gridTemplateColumns="1fr 1fr 1fr">
                    {walletCard.map(({ title, icon }) => (
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                        border="1px solid #E4E7EC"
                        py={2}
                        borderRadius="8px"
                        my={1}
                      >
                        <>{icon}</>
                        <Typography
                          textAlign="center"
                          fontSize="10px"
                          mt={2}
                          color="#344054"
                        >
                          {title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box
                  border="1px solid #E4E7EC"
                  borderRadius="8px"
                  overflow="hidden"
                >
                  <Box
                    bgcolor="#F6F6F6"
                    p={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography fontSize="12px" fontWeight={600}>
                      Transaction activities
                    </Typography>
                    <Button
                      variant="text"
                      sx={{ color: "#3E7EFF", fontWeight: "400" }}
                    >
                      Filter
                    </Button>
                  </Box>
                  <Box p={1}>
                    <Typography fontSize={12}>March, 2023</Typography>
                    {[1, 2, 3].map((_) => (
                      <Box
                        p={1}
                        border="1px solid #E4E7EC"
                        borderRadius="8px"
                        overflow="hidden"
                        my={1}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <div
                              style={{
                                backgroundColor: "#DA4733",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 24,
                                width: 24,
                              }}
                            >
                              <ArrowOutwardIcon
                                sx={{ color: "white", height: 18, width: 18 }}
                              />
                            </div>
                            <Typography fontSize={12} fontWeight={600}>
                              GoogleOne
                            </Typography>
                          </Box>
                          <Typography fontSize={12} fontWeight={600}>
                            $ 5
                          </Typography>
                        </Box>

                        <Typography color="#98A2B3" fontSize={12}>
                          Thu, May 23, 2024
                        </Typography>
                      </Box>
                    ))}
                    <Typography fontSize={12}>Feb, 2023</Typography>
                    {[1, 2, 3].map((_) => (
                      <Box
                        p={1}
                        border="1px solid #E4E7EC"
                        borderRadius="8px"
                        overflow="hidden"
                        my={1}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <div
                              style={{
                                backgroundColor: "#DA4733",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 24,
                                width: 24,
                              }}
                            >
                              <ArrowOutwardIcon
                                sx={{ color: "white", height: 18, width: 18 }}
                              />
                            </div>
                            <Typography fontSize={12} fontWeight={600}>
                              Apple Music
                            </Typography>
                          </Box>
                          <Typography fontSize={12} fontWeight={600}>
                            $ 30.95
                          </Typography>
                        </Box>

                        <Typography color="#98A2B3" fontSize={12}>
                          Thu, May 23, 2024
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
            {value === "card_details" && (
              <Box>
                {cardDetails.map(({ title, content }) => (
                  <Box mx={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 0.5,
                        my: 1,
                      }}
                    >
                      <Typography fontSize={12}>{title}</Typography>
                      <Typography fontSize={12} fontWeight={600}>
                        {content}{" "}
                        <ContentCopy
                          sx={{ height: 12, width: 12, color: "green" }}
                        />
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                ))}
                <Typography fontSize={12} mt={4}>
                  Need something else?
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    my: 1,
                    p: 1,
                    bgcolor: "transparent",
                    color: "red",
                    border: "1px solid #E4E7EC",
                    fontWeight: 400,
                    fontSize: "12px",
                    borderRadius: "8px",
                  }}
                >
                  Delete Card
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    p: 1,
                    bgcolor: "transparent",
                    color: "#98A2B3",
                    border: "1px solid #E4E7EC",
                    fontWeight: 400,
                    fontSize: "12px",
                    borderRadius: "8px",
                  }}
                >
                  Download report
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {created && (
        <Card
          variant="elevation"
          sx={{
            backgroundColor: "#E8FFF2",
            color: "white",
            width: "100%",
            animation: `${slideIn} 2s ease-in-out`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            position: "absolute",
            top: 0,
            borderRadius: 0,
            p: 1,
          }}
        >
          <Typography fontSize={14}> Card created successfully</Typography>
          <Check sx={{ color: "green" }} />
        </Card>
      )}
    </DashboardLayout>
  );
};

const walletCard = [
  {
    icon: <AddIcon sx={{ color: "#379E66" }} />,
    title: "Top Up Wallet",
  },
  {
    icon: <ArrowOutwardIcon sx={{ color: "#379E66" }} />,
    title: "Withdraw",
  },
  {
    icon: <ErrorOutline sx={{ color: "#98A2B3" }} />,
    title: "Freeze",
  },
];

const cardDetails = [
  {
    title: "Name",
    content: "Adedamola Adewale",
  },
  {
    title: "Card No",
    content: "0889 7947 8947 4839",
  },
  {
    title: "Exp Date",
    content: "02/2024",
  },
  {
    title: "CSV",
    content: "640",
  },
  {
    title: "Address",
    content: "24, Midborad Road",
  },
  {
    title: "State",
    content: "Middlestown",
  },
  {
    title: "Country",
    content: "United Kingdom",
  },
  {
    title: "Zip Code",
    content: "100216",
  },
];
