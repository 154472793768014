import { ArrowBack } from '@mui/icons-material';
import { Box, Button, IconButton, Rating, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ParkManagerDetails from './ParkManagerDetails';

const Details = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box height='100vh'>
        <Box p={2} sx={{ overflowY: 'scroll' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={700} fontSize={20}>
                Details
              </Typography>
            </Box>
          </Box>
          <ParkManagerDetails />

          <Box display='grid' sx={{ placeContent: 'center' }} my='24px'>
            <Typography
              fontSize={20}
              fontWeight={600}
              color='#101828'
              textAlign='center'
            >
              Rate Manager
            </Typography>
            <Rating size='large' sx={{ fontSize: '40px' }} />
          </Box>

          <Typography fontSize={12} fontWeight={400} py='8px' color='#101828'>
            Did something happen?
          </Typography>
          <Button
            sx={{
              fontWeight: 600,
              fontSize: 12,
              border: '1px solid #E4E7EC',
              borderRadius: '8px',
              color: 'red',
              p: '12px 6px 12px 6px',
              bgcolor: 'transparent',
              mb: '8px',
            }}
            variant='contained'
            fullWidth
          >
            Emergency
          </Button>
          <Button
            sx={{
              fontWeight: 600,
              fontSize: 12,
              border: '1px solid #E4E7EC',
              borderRadius: '8px',
              color: '#98A2B3',
              p: '12px 6px 12px 6px',
              bgcolor: 'transparent',
            }}
            variant='contained'
            fullWidth
          >
            Report Park Manager
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Details;
