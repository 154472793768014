import {
  Add,
  HelpOutlineRounded,
  NavigateNext,
  SendOutlined,
} from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Avatar, Divider, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();
  const FAQ = [
    {
      title: "What is Move9ja?",
      onClick: () => {
        navigate("/faq-1");
      },
    },
    {
      title: "What services does Move9ja offer?",
      onClick: () => {
        navigate("/faq-2");
      },
    },
    {
      title: "How do I create an account?",
      onClick: () => {
        navigate("/faq-3");
      },
    },
    {
      title: "How do I book a trip?",
      onClick: () => {
        navigate("/faq-4");
      },
    },
    {
      title: "What if I need help with my booking?",
      onClick: () => {
        navigate("/faq-5");
      },
    },
    {
      title: "What payment methods are accepted?",
      onClick: () => {
        navigate("/faq-6");
      },
    },

    {
      title: "Can I cancel or change my booking?",
      onClick: () => {
        navigate("/faq-7");
      },
    },
    {
      title: "Can I get a refund if I cancel my trip?",
      onClick: () => {
        navigate("/faq-8");
      },
    },
    {
      title: "How do I track my trip?",
      onClick: () => {
        navigate("/faq-9");
      },
    },
    {
      title: "Is my personal information secure?",
      onClick: () => {
        navigate("/faq-10");
      },
    },
  ];
  return (
    <>
      <Box height="100vh" mx={{ md: "14%" }}>
        <Box px={1} py={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              FAQ
            </Typography>
          </Box>

          <Box p={2}>
            {FAQ.map((item) => (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: 2,
                    cursor: "pointer",
                  }}
                  onClick={item.onClick}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <NavigateNext
                    fontSize="medium"
                    sx={{ height: 20, width: 20 }}
                  />
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Faq;
