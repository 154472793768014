import { Box, Typography } from "@mui/material";

import { ReactComponent as OrderArrow } from "./../assets/order-arrow.svg";
import { ReactComponent as BusIcon } from "./../assets/bus-icon.svg";

import { ReactComponent as ThunderIcon } from "./../assets/thunder-bolt.svg";
import { ReactComponent as CircularIcon } from "./../assets/circular-icon.svg";

const TripCard = ({
  onClick,
  to,
  from,
  parkname,
  parkbus_stop,
  stop_park,
}: {
  onClick?: () => void;
  to?: string;
  from?: string;
  parkname?: string;
  parkbus_stop?: string;
  stop_park?: string;
}) => {
  return (
    <Box
      onClick={onClick}
      my={1}
      mx={1}
      p={2}
      border="1px solid #E4E7EC"
      boxShadow="0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A"
      borderRadius="8px"
      sx={{ cursor: "pointer" }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="12px" textAlign="left" fontWeight={600}>
          {parkname},<br />
          {parkbus_stop}
        </Typography>
        <OrderArrow />
        <Typography fontSize="12px" textAlign="right" fontWeight={600}>
          {stop_park}
        </Typography>
      </Box>
      <Box
        display="flex"
        mt={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontSize="12px" color="#98A2B3">
          {from || "Lagos"}
        </Typography>
        <Typography fontSize="12px" color="#98A2B3">
          {to || "Illorin"}
        </Typography>
      </Box>

      <Box mt={1} display="flex" gap={1} alignItems="center">
        <Typography
          fontWeight={600}
          bgcolor="#ECFDF3"
          padding="2px 8px 2px 6px"
          fontSize="12px"
          color="#3538CD"
          borderRadius="16px"
          display="flex"
          gap={1}
          alignItems="center"
        >
          <Box height="8px" width="8px" borderRadius="50%" bgcolor="#379E66" />
          <span style={{ color: "#12B76A" }}> Completed</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default TripCard;
