import {
  Box,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { ReactComponent as Google } from "../../assets/google.svg";
import move9jaLogo from "../../assets/move9ja.svg";
import backGroundImage from "../../assets/move9jaBoy.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Otp = () => {
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 80px"
        display={{ xs: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        <Button
          sx={{
            bgcolor: "#DA4733",
            width: "109px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
          }}
          variant="contained"
        >
          Login
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "grid" },
          gridTemplateColumns: "40% 60%",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: { xs: "none", md: "grid" },
            placeItems: "end",
          }}
        >
          <Typography
            color="#fff"
            fontSize="32px"
            fontWeight={600}
            height="92px"
            width="324px"
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box p={2} mx="20%">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
            }}
            mb={1}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={800} fontSize={20}>
              Register
            </Typography>
          </Box>
          <Box mx={2}>
            <Typography mb={4} fontWeight={500}>
              Enter the verification code sent to your number
            </Typography>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                OTP code
              </InputLabel>
              <TextField
                placeholder="Paste OTP"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                sx={{
                  bgcolor: "#F9F9F9",
                  mt: 2,
                  mb: 2,
                  py: 1,
                  px: 2,
                  borderRadius: 3,
                  outline: "none",
                  border: "none",
                }}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
              onClick={() => {
                navigate("/complete-registration");
              }}
            >
              Continue
            </Button>
            <Typography
              fontSize={12}
              fontWeight={500}
              textAlign="center"
              mt={1}
            >
              Resend OTP 0:59
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Otp;
