import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const F5 = () => {
  const navigate = useNavigate();
  return (
    <Box height="100vh" mx={1} my={2} display="flex" flexDirection="column">
      <Box flexGrow={5} display="flex" flexDirection="column">
        <Box sx={{ display: "flex", alignItems: "start" }}>
          <IconButton
            onClick={() => {
              navigate("/faq");
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={700} fontSize={20}>
            What if I need help with my booking?
          </Typography>
        </Box>
        <Typography fontSize={14} mx={2} mt={6}>
          Our support team is available 24/7 to assist with any questions or
          issues.
        </Typography>
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column" mt="auto">
        <Divider />
        <Typography my={2} textAlign="center" fontWeight={600} fontSize={16}>
          Does this information solve your issue?
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="8px"
        >
          <Button
            sx={{
              fontWeight: 400,
              bgcolor: "transparent",
              borderRadius: "100px",
              p: "6px 35px",
              color: "#000000",
              border: "1px solid #CFCFCF",
            }}
            variant="outlined"
          >
            YES
          </Button>
          <Button
            sx={{
              fontWeight: 400,
              bgcolor: "transparent",
              borderRadius: "100px",
              p: "6px 35px",
              color: "#000000",
              border: "1px solid #CFCFCF",
            }}
            variant="outlined"
          >
            No
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default F5;
