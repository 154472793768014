import { ArrowBack, Check } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ProfileLayout } from './ProfileLayout';
import { useThemeContext } from '../../Context/themeContext';
import { useNavigate } from 'react-router-dom';

const AppAppearance = () => {
  const { mode, toggleTheme, applySystemSettings } = useThemeContext(); // Access the context
  const navigate = useNavigate();

  return (
    <ProfileLayout>
      <Box>
        <Box mb={1} p={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
              cursor: 'pointer',
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              App Appearance
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            my={2}
            mx='5%'
            sx={{ cursor: 'pointer' }}
            onClick={() => toggleTheme('light')}
          >
            <Typography fontSize={12} fontWeight={600}>
              Always Light
            </Typography>
            {mode === 'light' && <Check sx={{ color: 'green' }} />}
          </Box>
          <Divider />
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            my={2}
            mx='5%'
            sx={{ cursor: 'pointer' }}
            onClick={() => toggleTheme('dark')}
          >
            <Typography fontSize={12} fontWeight={600}>
              Always Dark
            </Typography>
            {mode === 'dark' && <Check sx={{ color: 'green' }} />}
          </Box>
          <Divider />
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            my={2}
            mx='5%'
            sx={{ cursor: 'pointer' }}
            onClick={applySystemSettings}
          >
            <Typography fontSize={12} fontWeight={600}>
              Apply System Settings
            </Typography>
          </Box>
          <Divider />
        </Box>
      </Box>
    </ProfileLayout>
  );
};

export default AppAppearance;
