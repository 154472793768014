import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import move9jaLogo from "../../assets/move9ja.svg";
import move9jaBoy from "../../assets/move9jaBoy.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CompleteEmailReg = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 80px"
        display={{ xs: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        <Button
          sx={{
            bgcolor: "#DA4733",
            width: "109px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
          }}
          variant="contained"
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "grid" },
          gridTemplateColumns: "40% 60%",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${move9jaBoy})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: { xs: "none", md: "grid" },
            placeItems: "end",
          }}
        >
          <Typography
            color="#fff"
            fontSize="32px"
            fontWeight={600}
            height="92px"
            width="324px"
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display="flex"
          height={{ md: "100vh" }}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            width={{ xs: "100%", md: "510px" }}
            p="0px 24px"
            mt={{ md: "70px", xs: "40px" }}
          >
            <Box display="flex" alignItems="center" gap={1} mb="12px">
              <ArrowBack onClick={() => navigate(-1)} />
              <Typography fontWeight={800} fontSize={20}>
                Complete Registration
              </Typography>
            </Box>
            <Typography mb={{ md: "42px", xs: "32px" }}>
              Complete profile details correctly
            </Typography>
            <form>
              {/* //onSubmit={handleSubmit(onSubmit)} */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink sx={{ fontWeight: 800 }}>
                    First Name
                  </InputLabel>
                  <TextField
                    placeholder="John"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: "#F9F9F9",
                      mt: 2.5,
                      py: 1,
                      px: 2,
                      borderRadius: "10px",
                    }}
                    type="text"
                    // value={firstName}
                    // onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink sx={{ fontWeight: 800 }}>
                    Last Name
                  </InputLabel>
                  <TextField
                    placeholder="Doe"
                    type="text"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: "#F9F9F9",
                      borderRadius: "10px",
                      borderStyle: "none",
                      mt: 2.5,
                      py: 1,
                      px: 2,
                    }}
                    // value={lastName}
                    // onChange={(e) => setLastName(e.target.value)}
                  />
                </FormControl>
              </Box>

              <FormControl variant="standard" fullWidth sx={{ mt: 1 }}>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Email Address
                </InputLabel>
                <TextField
                  placeholder="Enter a valid email"
                  type="email"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2,
                    borderRadius: "10px",
                    borderStyle: "none",
                    py: 1,
                    px: 2,
                  }}
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <Button
                // disabled={!!mutation.isPending}
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  fontWeight: 400,
                  borderRadius: "8px",
                  p: "10px 16px",
                  mt: "14px",
                }}
                onClick={() => navigate("/profile-completed")}
              >
                {/* {mutation.isPending ? "Loading.." : "Login"} */}
                Continue
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteEmailReg;
