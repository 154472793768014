import ArrowBack from '@mui/icons-material/ArrowBack';
import { Button, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';

import Move9jaCard from '../shared/Move9jaCard';
import BottomDrawer from '../shared/BottomDrawer';
import PaymentSuccess from '../shared/PaymentSuccess';
import { useState } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import { Close } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { getAddons } from '../services/addons';

const BookingDetails = () => {
  const navigate = useNavigate();
  const [selectedAddons, setSelectedAddons] = useState<number[]>([]);
  const location = useLocation();
  const { searchResults, selectedResult, selectedSeat } = location.state || {};

  const { data } = useQuery({
    queryKey: ['ADD_ONS'],
    queryFn: getAddons,
  });

  console.log('Add ons', data?.data?.addons);

  const onClickAddons = (index: number) => {
    setSelectedAddons((prevSelectedAddons) =>
      prevSelectedAddons.includes(index)
        ? prevSelectedAddons.filter((i) => i !== index)
        : [...prevSelectedAddons, index]
    );
  };

  const calculateTotalPrice = () => {
    return selectedAddons.reduce((total, index) => {
      const addon = Cards[index];
      return total + (addon ? addon.amount : 0);
    }, 0);
  };
  console.log(selectedSeat);
  const handleContinue = () => {
    const selectedAddonsIds = selectedAddons.map((index) => Cards[index].id);
    const totalPrice = calculateTotalPrice();
    const busFee = selectedResult?.price; // data fetched from the search api selectedResult
    const busId = selectedResult?.vehicleId;
    const bookingData = {
      bus_id: busId,
      selected_seat: selectedSeat,
      travel_date: '2024-08-09',
      addons_id: selectedAddonsIds,
      type: 'single_trip', // or "round_trip"
    };
    navigate('/summary', {
      state: {
        bookingData,
        totalPrice,
        busFee,
        searchResults,
        selectedResult,
        selectedSeat,
      },
    }); // Passnig bookingData to the summary page and other useful data
  };

  return (
    <DashboardLayout>
      <Box height='auto' mx={{ md: '14%' }}>
        <Box
          height='168px'
          bgcolor='#000'
          color='#FFF'
          display={{ md: 'flex', xs: 'none' }}
          alignItems='center'
          justifyContent='space-between'
          px={2}
        >
          <Box>
            <Typography fontSize='20px' fontWeight={700}>
              Lagos - Ilorin
            </Typography>
            <Typography fontSize='14px' fontWeight={400}>
              5 parks result(s)
            </Typography>
            <Typography fontSize='14px' fontWeight={400}>
              10th May
            </Typography>
          </Box>
          <Box>
            <Close />
            <Typography height='25px' />
            <Typography
              fontSize='14px'
              fontWeight={400}
              sx={{ textDecoration: 'underline' }}
            >
              Modify search
            </Typography>
          </Box>
        </Box>
        <Box p={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={700} fontSize={20}>
                Booking details
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={2}>
          <Move9jaCard marginTop={0} title='Add Ons'>
            <Box
              p={1}
              display='grid'
              gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}
              gap='8px'
            >
              {data?.data?.addons.map((card: any, index: number) => (
                <Box
                  key={index}
                  display='flex'
                  alignItems='start'
                  justifyContent='space-between'
                  borderRadius='8px'
                  border='1px solid #E4E7EC'
                  p={1}
                  mb={1}
                >
                  <Box>
                    <Typography fontSize='12px' fontWeight={600} mb={1}>
                      {card.name}
                    </Typography>
                    <Typography fontSize='12px' fontWeight={400} mb={1}>
                      {card.description}
                    </Typography>
                    <Typography
                      fontSize='12px'
                      fontWeight={600}
                      color='#3E7EFF'
                    >
                      + NGN {card?.price.toLocaleString()}
                    </Typography>
                  </Box>
                  <Chip
                    label={
                      selectedAddons.includes(index) ? '- Remove' : '+ Add'
                    }
                    onClick={() => onClickAddons(index)}
                    sx={{
                      bgcolor: selectedAddons.includes(index)
                        ? '#FFF7E8'
                        : '#F6F6F6',
                      color: selectedAddons.includes(index)
                        ? '#DA4733'
                        : '#5D5C63',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Move9jaCard>
          <Button
            sx={{
              borderRadius: '8px',
              p: '12px 24px',
              mt: 2,
              width: { xs: '100%', md: '300px' },
            }}
            variant='contained'
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default BookingDetails;

const Cards = [
  {
    id: '66b0a465adf8f2838713daf6',
    title: 'Pick me at my nearest Location',
    subTitle: 'Along Kola Bus-stop',
    amount: 2000,
  },
  {
    id: '66b0a465adf8f2838713daf7',
    title: 'Luggage Space',
    subTitle: 'Save boot space for your ... (added)',
    amount: 2000,
  },
  {
    id: '66b0a465adf8f2838713daf8',
    title: 'Park Lounge',
    subTitle: 'Have a place of rest before you travel',
    amount: 500,
  },
  {
    id: '66b0a465adf8f2838713daf9',
    title: 'Take Away (By Chowdeck)',
    subTitle: 'Get our Move9ja Jollof pack for app...',
    amount: 2000,
  },
  {
    id: '66b0a465adf8f2838713dafA',
    title: 'Travel insurance (AXL)',
    subTitle: 'Buy medical insurance in case of emergency',
    amount: 1750,
  },
  {
    id: '66b0a465adf8f2838713dafB',
    title: 'Reminder',
    subTitle: 'Received Text message of your travel',
    amount: 200,
  },
  {
    id: '66b0a465adf8f2838713dafC',
    title: 'Take Away (By Chowdeck)',
    subTitle: 'Get our Move9ja Jollof pack for app..',
    amount: 2000,
  },
];
