import { ArrowUpward, StarOutlineRounded } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Move9jaCard from "./Move9jaCard";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";

import Call from "./../assets/call.png";
import Messag from "./../assets/messag.png";

const ParkManagerDetails = ({
  fullname,
  phone,
}: {
  fullname?: string;
  phone?: string;
}) => {
  return (
    <Move9jaCard marginTop={2} title="Pak Manager's Details">
      <Box p="12px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          py="9px"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Avatar sx={{ bgcolor: "#EE46BC", color: "white" }}>OE</Avatar>
            <Box>
              <Typography fontSize="12px" fontWeight={600}>
                {fullname}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={600}
                display="flex"
                alignItems="center"
                gap="4px"
              >
                <StarOutlineRounded /> 4.8
              </Typography>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Link
              style={{
                height: "36px",
                width: "36px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#000",
              }}
              to={`sms:+${phone}`}
            >
              <img src={Messag} alt="" height="20px" width="20px" />
            </Link>

            <Link
              style={{
                height: "36px",
                width: "36px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "green",
              }}
              to={`tel:+${phone}`}
            >
              <img src={Call} alt="" height="20px" width="20px" />
            </Link>
          </div>
        </Box>
      </Box>
    </Move9jaCard>
  );
};

export default ParkManagerDetails;
