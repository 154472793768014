import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import ParkManagerDetails from "../shared/ParkManagerDetails";
import Move9jaCard from "../shared/Move9jaCard";
import CustomTimeline from "../shared/TravelLocationTimeline";
import { ArrowBack, ArrowUpward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Move9jaTravelTimeline from "../shared/Move9jaTravelTimeline";
import { handleDownload } from "../utils/downloadFile";

const TravelDetail = ({
  bookingDate = "Thu, Sept, 2024",
  bookingId = "",
  busName = "",
  timeOfTravel = "",
  seatNumber = "",
  parkName = "",
  price = 0,
  from = "",
  to = "",
  parkBusStop = "",
  stopLocation = "",
  estimatedHours = "",
}: {
  bookingDate?: string | null;
  bookingId?: string | null;
  busName?: string | null;
  timeOfTravel?: string | null;
  seatNumber?: string | null;
  parkName?: string | null;
  price?: number | null;
  from?: string | null;
  to?: string | null;
  parkBusStop?: string | null;
  stopLocation?: string | null;
  estimatedHours?: string | null;
}) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box p={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Travel details
            </Typography>
          </Box>
        </Box>
        <Box id="travel-details">
          <Typography fontWeight={700} fontSize="24px">
            Order:{bookingId || "#JJENFNEI-345J"}
          </Typography>
          <Chip
            size="small"
            sx={{ bgcolor: "#F0F9FF", color: "#026AA2" }}
            icon={<ArrowUpward />}
            label={
              <Typography sx={{ ml: 0.5 }} fontWeight={600}>
                En Route
              </Typography>
            }
          />
          <Move9jaCard
            title={`${bookingDate}`}
            endText={`${estimatedHours || 6}h`}
          >
            <CustomTimeline
              seat={seatNumber}
              from={from}
              to={to}
              startTime={timeOfTravel}
              parkBusStop={parkBusStop}
              stopLocation={stopLocation}
              parkName={parkName}
              estimatedHours={estimatedHours}
            />
          </Move9jaCard>
          <ParkManagerDetails />
          <Move9jaTravelTimeline startTime={timeOfTravel} />
        </Box>
        <Button
          fullWidth
          sx={{
            borderRadius: "8px",
            bgcolor: "#F2F4F7",
            color: "#000",
            p: "10px 14px",
            mt: 2,
          }}
          variant="contained"
        >
          Download Ticket
        </Button>
        <Button
          fullWidth
          sx={{ borderRadius: "8px", p: "10px 14px", mt: 2, bgcolor: "#000" }}
          variant="contained"
          onClick={() => handleDownload("travel-details", "Travel Details")}
        >
          Download Reciept
        </Button>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
            fontSize: "14px",
          }}
        >
          Any issue with travel?{" "}
          <span
            style={{
              color: "#D10121",
              textDecoration: "underline",
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: "3px",
            }}
            role="button"
            onClick={() => navigate("/report-bug")}
          >
            Report a problem
          </span>{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default TravelDetail;
