// Function to convert camelCase to snake_case
const toSnakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
};

// Function to convert an object's keys from camelCase to snake_case
export const convertKeysToSnakeCase = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = toSnakeCase(key);
      newObj[newKey] = obj[key];
    }
  }
  return newObj;
};

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.startsWith('0')) {
    return '234' + phoneNumber.slice(1);
  }
  return '234' + phoneNumber;
}

export function formatCurrency(amount: number): string {
  return amount.toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
  });
}
