import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";

import confirmIcon from "assets/confirm.svg";
import BottomDrawer from "../BottomDrawer";
import { FeedbackRecieved } from "./Feedback";

const ReasonsForDecline = () => {
  const [open, setOpen] = useState(false);
  const reasons = [
    {
      title: "Safety Concerns",
    },
    {
      title: "Personal Emergencies",
    },
    {
      title: "Inadequate Vehicle",
    },
    {
      title: "Excessive Wait Time",
    },
    {
      title: "Incorrect Pickup Location",
    },
    {
      title: "Navigation Problems",
    },
    {
      title: "Weather Conditions",
    },
    {
      title: "Language Barrier",
    },
    {
      title: "Unresponsive Passenger",
    },
    {
      title: "Other reason",
    },
  ];
  return (
    <>
      <Box p={2}>
        <Typography
          py={1}
          variant="body2"
          fontSize={22}
          textAlign="center"
          fontWeight={700}
        >
          Reasons for decline
        </Typography>
        <Box mb={3}>
          {reasons.map(({ title }) => (
            <Box>
              <Box
                sx={{
                  p: 1,
                  my: 1,
                }}
              >
                <Typography fontSize={12}>{title}</Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>

        <Button
          fullWidth
          sx={{
            borderRadius: "8px",
            bgcolor: "#379E66",
            color: "#fff",
            mt: 4,
          }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Submit
        </Button>
      </Box>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <FeedbackRecieved />
      </BottomDrawer>
    </>
  );
};

export default ReasonsForDecline;
