import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import BottomDrawer from './BottomDrawer';
import { FeedbackRecieved } from './BottomDrawerContent/Feedback';
import { toast } from 'react-hot-toast';

const ReportDriver = () => {
  const [openFeedback, setOpenFeedback] = useState(false);
  return (
    <>
      <Box p={2} height='100vh'>
        <Typography textAlign='center' fontWeight={600} fontSize='16px'>
          Report Driver
        </Typography>
        {COMPLAIN.map(({ text }, i) => (
          <Box
            onClick={() => toast.error(`Ohh! ${text.toLowerCase()}?`)}
            py='12px'
            borderBottom='1px solid #F2F4F7'
            sx={{ cursor: 'pointer' }}
          >
            <Typography>{text}</Typography>
          </Box>
        ))}
        <Typography py='12px'>Other reason</Typography>
        <Button
          variant='contained'
          fullWidth
          sx={{ borderRadius: '8px', p: '12px 24px', mt: 2 }}
          onClick={() => {
            setOpenFeedback(true);
          }}
        >
          Submit
        </Button>
      </Box>
      <BottomDrawer open={openFeedback} onClose={() => setOpenFeedback(false)}>
        <FeedbackRecieved />
      </BottomDrawer>
    </>
  );
};

export default ReportDriver;

const COMPLAIN = [
  { text: 'Driver is harrassing' },
  { text: 'Driver is a Druck' },
  { text: 'Driver is Noisy' },
  { text: 'Driver was rude' },
  { text: 'Driver took a poor route' },
  { text: 'Driver was rough' },
  { text: 'Driver was reckless' },
];
