import { ContentCopy } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

import handsGrip from "../assets/hands-grid.svg";

const Withdraw = () => {
  return (
    <>
      <Box p={3}>
        <Typography
          variant="body2"
          fontSize={22}
          textAlign="left"
          fontWeight={700}
          mb={2}
        >
          Withdrawal
        </Typography>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink sx={{ fontWeight: 800, mb: 2 }}>
            Enter Amount
          </InputLabel>

          <TextField
            type="number"
            variant="standard"
            placeholder="#1,000"
            InputProps={{ disableUnderline: true }}
            sx={{
              bgcolor: "#F9F9F9",
              my: 2,
              borderRadius: "10px",
              borderStyle: "none",
              py: 1,
              px: 2,
            }}
          />
        </FormControl>
        <Box mt={2}>
          <Button
            fullWidth
            disableElevation
            sx={{
              p: "12px 24px",
              borderRadius: 2.5,
            }}
            variant="contained"
            content="l"
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Withdraw;

const top_up = [
  {
    title: "Account Name",
    sub: "Move9ja/Adedamola",
  },
  {
    title: "Account details",
    sub: "0908893282",
  },
  {
    title: "Bank Name",
    sub: "Providus Bank",
  },
];
