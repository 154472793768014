import http from "./http";

export const search = async (params: {
  date: string;
  type: string;
  from: string;
  to: string;
  return_date: string;
}) => {
  const res = await http.get("/customer/search", { params });
  return res.data;
};
