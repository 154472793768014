import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ProfileLayout } from "./ProfileLayout";
import { useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();
  return (
    <ProfileLayout>
      <Box mx="5%">
        <Box pt={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Back
            </Typography>
          </Box>
        </Box>
        <Typography mt={1} mb={3} fontSize={16}>
          Terms and Conditions
        </Typography>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Services
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Move9ja shall provide the Services to the Customer in accordance
            with the booking details.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Move9ja reserves the right to cancel or modify the Services due to
            unforeseen circumstances.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Payment
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - The Customer shall pay the agreed fare and any additional charges.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Payment shall be made in accordance with the payment terms.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Liability
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Move9ja shall not be liable for any loss, damage, or injury
            arising from:
          </Typography>
          <ul style={{ paddingLeft: "16px" }}>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Kidnapping or any criminal act by third parties.
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Force majeure events (e.g. natural disasters, political
                instability).
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Compliance with applicable laws and regulations.
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Move9ja shall not be liable for any indirect or consequential
                losses.
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Indemnity
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - The Customer shall indemnify Move9ja against any claims, damages,
            or expenses arising from:
          </Typography>
          <ul style={{ paddingLeft: "16px" }}>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                The Customer's breach of this Agreement.
              </Typography>
            </li>
            <li>
              <Typography fontWeight={400} fontSize={12}>
                Any third-party claims related to the Services.
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Governing Law
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - This Agreement shall be governed by and construed in accordance
            with the laws of the Federal Republic of Nigeria.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - Any disputes shall be resolved through arbitration in accordance
            with the rules of the Nigerian Arbitration and Conciliation Act.
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography fontWeight={700} fontSize={16}>
            Entire Agreement
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - This Agreement constitutes the entire understanding between the
            parties.
          </Typography>
          <Typography fontWeight={400} fontSize={12}>
            - This agreement is subjected to review at anytime and if their is
            any changes we will notify you via email or in app notifications
            bell.
          </Typography>
        </Box>

        <Box height="200px" />
        <Button variant="contained" fullWidth sx={{ fontWeight: 400 }}>
          Contact Us
        </Button>
        <Typography fontSize="12px" textAlign="center" mt="12px" mb="24px">
          By booking the Services, the Customer acknowledges that they have
          read, understood, and agreed to be bound by these Terms and
          Conditions.
        </Typography>
      </Box>
    </ProfileLayout>
  );
};

export default Terms;
