import { Box, Button, Typography } from "@mui/material";
import bgImg from "./../../assets/move9jaBoy.png";
import { Apple, LastPage } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Google } from "../../assets/google.svg";

export const Landing = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          background: "url(" + bgImg + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
          height: "100vh",
          position: "fixed",
          width: "100vw",
          margin: 0,
          padding: 0,
          display: "grid",
          placeItems: "end",
        }}
      >
        <Box
          sx={{
            mx: 2,
            mb: 13,
            display: "flex",
            justifyContent: "center",
            gap: 1,
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={600} fontSize={32} color="white">
            Request a bus ride, hop in, and go.
          </Typography>
          <Button
            variant="contained"
            endIcon={<LastPage />}
            onClick={() => {
              navigate("/login");
            }}
            sx={{
              backgroundColor: "#DA4733",
              width: "100%",
              p: 1.5,
              fontWeight: 500,
              borderRadius: 2,
            }}
          >
            Get Started
          </Button>
          <Button
            sx={{
              fontWeight: 400,
              borderRadius: "8px",
              p: "10px 16px",
              color: "black",
              bgcolor: "white",
            }}
            fullWidth
            startIcon={<Google style={{ height: "24px", width: "24px" }} />}
            variant="contained"
          >
            Continue with Google
          </Button>
          <Button
            sx={{
              fontWeight: 400,
              p: "10px 16px",
              borderRadius: "8px",
              bgcolor: "black",
            }}
            fullWidth
            startIcon={<Apple sx={{ height: "24px", width: "24px" }} />}
            variant="contained"
          >
            Continue with Apple
          </Button>
        </Box>
      </Box>
    </>
  );
};
